import React from 'react';
import { useEffect, useState, useRef } from 'react';

import { UUID } from '@shared/utils/uuid';

import { Context } from './context';
import { ISelection } from './selection';

export const Manager: React.FC<{
  saving?: boolean;
  onSave(file: File, signedID: string): void;
  onUploading?(uploading: boolean): void;
  children(props: { uploads: ISelection[]; uploading: boolean }): React.ReactNode;
}> = ({ onSave, onUploading, saving, children }) => {
  const [selections, setSelections] = useState<ISelection[]>([]);
  const uploads = selections.filter((selection) => !selection.signedID);
  const uploading = uploads.length > 0;
  const onUploadingRef = useRef(onUploading);

  useEffect(() => {
    onUploadingRef.current = onUploading;
  }, [onUploading]);

  useEffect(() => {
    if (!onUploadingRef.current) return;
    onUploadingRef.current(uploading);
  }, [uploading]);

  const onSelect = (file: File) => {
    setSelections((current) => [
      ...current,
      {
        file,
        uuid: UUID(),
      },
    ]);
  };

  const onCancel = (_: File, uuid: string) => {
    setSelections((current) => current.filter((selection) => selection.uuid !== uuid));
  };

  const onUpload = (file: File, uuid: string, signedID: string) => {
    onSave(file, signedID);
    setSelections((current) =>
      current.map((selection) => (selection.uuid === uuid ? { ...selection, signedID } : selection)),
    );
  };

  useEffect(() => {
    const onDrag = (event: DragEvent) => {
      const { dataTransfer } = event;
      if (!dataTransfer) {
        return;
      }

      event.preventDefault();
      event.stopPropagation();
    };

    const onDrop = (event: DragEvent) => {
      const { dataTransfer } = event;
      if (!dataTransfer) {
        return;
      }

      event.preventDefault();
      event.stopPropagation();

      const { files } = dataTransfer;

      setSelections((current) => [
        ...current,
        ...Array.from(files).map((file) => ({
          file,
          uuid: UUID(),
        })),
      ]);
    };

    document.addEventListener('dragenter', onDrag);
    document.addEventListener('dragleave', onDrag);
    document.addEventListener('dragover', onDrag);
    document.addEventListener('drop', onDrop);

    return () => {
      document.removeEventListener('dragenter', onDrag);
      document.removeEventListener('dragleave', onDrag);
      document.removeEventListener('dragover', onDrag);
      document.removeEventListener('drop', onDrop);
    };
  }, []);

  return (
    <Context.Provider
      value={{ onSave, onSelect, onCancel, onUpload, uploads, saving }}
      children={children({ uploads, uploading })}
    />
  );
};
