import { COLORS, FontWeight, Text } from '@clutter/clean';
import styled from '@emotion/styled';
import React from 'react';

import { LaborCostDetailsFragment, Maybe, UpcomingStorageOnboardingFragment } from '@portal/schema';

import { PlanUpdateLink } from './plan_update_link';

const Container = styled.div`
  margin-top: 32px;
`;

const ServiceFeeContainer = styled.div`
  display: flex;
  justify-content: space-between;
  margin-bottom: 8px;
  &:last-child {
    margin-bottom: 0;
  }
  div:last-child {
    text-align: right;
    padding-left: 8px;
  }
`;

const ServiceFees = styled.div`
  background-color: ${COLORS.grayBackground};
  border-radius: 4px;
  padding: 24px;
`;

const ServicePlanHeader = styled.div`
  margin-bottom: 12px;
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

const Title = styled(Text.Title)`
  color: ${COLORS.panther};
`;

const ServiceDescription = styled(Text.Callout)`
  color: ${COLORS.storm};
  padding-top: 16px;
`;

const Callout = styled(Text.Callout)<{ noWrap?: boolean }>`
  color: ${COLORS.panther};
  white-space: ${({ noWrap }) => noWrap && 'pre'};
`;

const Divider = styled.hr`
  margin: 24px 0;
`;

const Emphasis = styled.span`
  color: ${COLORS.panther};
  font-weight: ${FontWeight.Medium};
`;

const CostDetails: React.FC<{ laborCostDetails?: LaborCostDetailsFragment }> = ({ laborCostDetails }) => (
  <>
    {laborCostDetails?.discountTierDescription}
    {laborCostDetails?.discountTierDescription && <br />}
    {laborCostDetails?.formattedCost}
  </>
);

export const ServicePlan: React.FC<{
  onboardingCostDetails?: LaborCostDetailsFragment;
  finalOrderCostDetails?: LaborCostDetailsFragment;
  storeAndMoveCostDetails?: LaborCostDetailsFragment;
  onboarding?: Maybe<UpcomingStorageOnboardingFragment>;
  allowPlanUpdate: boolean;
  subsequentPickupCostDetails?: LaborCostDetailsFragment;
  subsequentReturnCostDetails?: LaborCostDetailsFragment;
}> = ({
  onboardingCostDetails,
  finalOrderCostDetails,
  storeAndMoveCostDetails,
  onboarding,
  allowPlanUpdate,
  subsequentPickupCostDetails,
  subsequentReturnCostDetails,
}) => {
  const description = (() => {
    if (onboarding?.tags.includes('Basic'))
      return (
        <>
          You selected a <Emphasis>Basic</Emphasis> service package. This means you will disassemble and pack your own
          items. When we arrive, your pre-packed items should be grouped together.
          <br />
          <br />
          We’ll wrap your furniture for protection and load everything onto our truck.
        </>
      );
    return (
      <>
        You selected a <Emphasis>Pickup & Packing</Emphasis> service package. This means you don’t need to lift a
        finger. We’ll bring complimentary boxes, packing materials and disassembly tools. Our team will professionally
        pack and wrap your items, then load them onto our truck. You can save time and money by packing boxes and
        disassembling items ahead of time.
      </>
    );
  })();

  return (
    <Container>
      <ServicePlanHeader>
        <Title size="medium">Service Plan</Title>
        {allowPlanUpdate && <PlanUpdateLink container={'service_plan'} onboardingID={onboarding?.id} />}
      </ServicePlanHeader>
      <ServiceFees>
        <ServiceFeeContainer>
          <Callout weight={FontWeight.Medium} noWrap>
            {storeAndMoveCostDetails ? 'Initial Pickup & Move' : 'Initial Pickup'}
          </Callout>
          <Callout weight={FontWeight.Medium}>
            <CostDetails laborCostDetails={storeAndMoveCostDetails || onboardingCostDetails} />
          </Callout>
        </ServiceFeeContainer>
        {allowPlanUpdate && <ServiceDescription>{description}</ServiceDescription>}
        <Divider />
        <ServiceFeeContainer>
          <Callout weight={FontWeight.Medium} noWrap>
            Additional Pickups
          </Callout>
          <Callout weight={FontWeight.Medium}>
            <CostDetails laborCostDetails={subsequentPickupCostDetails} />
          </Callout>
        </ServiceFeeContainer>
        <Divider />
        <ServiceFeeContainer>
          <Callout weight={FontWeight.Medium} noWrap>
            Additional Deliveries
          </Callout>
          <Callout weight={FontWeight.Medium}>
            <CostDetails laborCostDetails={subsequentReturnCostDetails} />
          </Callout>
        </ServiceFeeContainer>
        <Divider />
        <ServiceFeeContainer>
          <Callout weight={FontWeight.Medium} noWrap>
            Final Delivery
          </Callout>
          <Callout weight={FontWeight.Medium}>
            <CostDetails laborCostDetails={finalOrderCostDetails} />
          </Callout>
        </ServiceFeeContainer>
      </ServiceFees>
    </Container>
  );
};
