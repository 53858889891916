import React, { useState } from 'react';
import { Button, COLORS, Text, Textarea } from '@clutter/clean';
import styled from '@emotion/styled';

import {
  AppointmentHubDocument,
  Status,
  useEstimationUploadsQuery,
  useVirtualWalkthroughSubmitMutation,
} from '@portal/schema';

import { client } from '@portal/libraries/apollo';
import { StepContainer } from './step_container';
import { BaseStepType } from './data';

const SUCCESS_MESSAGE = 'Your virtual walkthrough has been successfully submitted!';
const ERROR_MESSAGE = 'The request could not be submitted. Contact Customer Care for assistance.';

const StyledFlexBox = styled.div`
  display: flex;
  gap: 24px;
  flex-direction: column;
`;

const StyledTextArea = styled(Textarea)`
  width: 100%;
  height: 170px;
`;

export const StyledGrid = styled.div`
  display: grid;
  grid-column-gap: 12px;
  grid-row-gap: 12px;
  justify-content: start;
  grid-template-columns: repeat(auto-fit, 100px);
`;

export const StyledButton = styled(Button)`
  margin-bottom: 16px;
  height: 100px;
  width: 100px;
  max-height: 100px;
  max-width: 100px;
  min-width: unset;
  padding: 0;
  background: transparent;
  color: ${COLORS.tealPrimary};
  cursor: pointer;
`;

export const Notes: React.FC<BaseStepType> = (props) => {
  const { next, orderID, setError } = props;
  const [customerNotes, setCustomerNotes] = useState<string>('');

  const { data } = useEstimationUploadsQuery({
    client,
    variables: { orderID },
  });

  const [save, { loading: submitting }] = useVirtualWalkthroughSubmitMutation({
    client,
    refetchQueries: [{ query: AppointmentHubDocument, variables: { orderID } }],
  });

  const handleSubmit = async (): Promise<{ status: Status; message: string }> => {
    const response = await save({
      variables: {
        input: {
          orderID,
          customerNotes,
        },
      },
    });

    const status = response.data?.virtualWalkthroughSubmit?.status ?? Status.Unprocessable;
    return Promise.resolve({
      status,
      message: status === Status.Ok ? SUCCESS_MESSAGE : ERROR_MESSAGE,
    });
  };

  return (
    <StepContainer
      {...props}
      loading={submitting}
      canPrev={true}
      canNext={customerNotes.length > 0 || !!(data?.estimationUploads && data.estimationUploads.length > 0)}
      next={async () => {
        const response = await handleSubmit();
        if (response.status === Status.Ok) {
          next();
        } else {
          setError(response.message);
        }
      }}
    >
      <StyledFlexBox>
        <Text.Title size="medium">Additional Information</Text.Title>
        <StyledTextArea
          placeholder="List any additional information that you think would be useful for your team to know."
          value={customerNotes}
          onChange={(e) => setCustomerNotes(e.currentTarget.value)}
        />
      </StyledFlexBox>
    </StepContainer>
  );
};
