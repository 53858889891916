import { useExperimentsQuery } from '@portal/schema';
import React, { useContext, useMemo } from 'react';

type ContextValue = {};

const ExperimentContext = React.createContext<ContextValue | undefined>(undefined);

export const useExperimentsContext = () => {
  const value = useContext(ExperimentContext);
  if (!value) {
    throw new Error('ExperimentContextProvider not found!');
  }
  return value;
};

export const INACTIVE_VALUE: ContextValue = {};

export const ExperimentsContextProvider: React.FC<{
  value?: ContextValue;
  children?: React.ReactNode;
  renderWhileLoading?: boolean;
}> = ({ value: overrideValue, renderWhileLoading = true, children }) => {
  const { data, loading } = useExperimentsQuery();

  const value: ContextValue = useMemo(() => (data ? {} : INACTIVE_VALUE), [data]);

  return (
    <ExperimentContext.Provider value={overrideValue ?? value}>
      {renderWhileLoading || !loading ? children : null}
    </ExperimentContext.Provider>
  );
};
