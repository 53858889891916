import { BillingTabs } from '@portal/components/billing/tabs';
import { build } from '@shared/utils/routes';

export enum ItemStatusSlug {
  Available = 'available',
  Requested = 'requested',
  Returned = 'returned',
  Delayed = 'delayed',
}

export const DEFAULT_ITEMS_STATUS_SLUG = ItemStatusSlug.Available;

export const claimsURL = () => '/claims';
export const buildClaimURL = () => '/claims/new';

export const DEFAULT_CLAIM_ACTION = 'start';
export type ClaimAction =
  | 'start'
  | 'kind'
  | 'ready'
  | 'damages'
  | 'lost'
  | 'property'
  | 'confirm'
  | 'thanks'
  | 'review';
export const claimURL = (uuid: string, action?: ClaimAction) => build(claimsURL(), uuid, action);

export type ClaimItemSelectionAction = 'name' | 'product_url' | 'declaration' | 'photos' | 'receipts' | 'submitted';
export const claimItemSelectionURL = (uuid: string, id: string, action?: ClaimItemSelectionAction) =>
  build(claimURL(uuid), 'item_selections', id, action);

export type ClaimPropertySelectionAction = 'name' | 'owner' | 'photos' | 'quote' | 'declaration';
export const claimPropertySelectionURL = (uuid: string, action?: ClaimPropertySelectionAction) =>
  build(claimURL(uuid), 'property_selection', action);

export type ClaimIssueSelectionAction = 'issue' | 'description' | 'declaration' | 'photos';
export const claimIssueSelectionURL = (uuid: string, action?: ClaimIssueSelectionAction) =>
  build(claimURL(uuid), 'issue_selection', action);

export const ordersURL = (action?: string) => build('/appointments', action);
export const orderStepURL = (step: string) => build(ordersURL(), 'steps', step);
export const orderURL = (id: string, action?: string) => build(ordersURL(), id, action);
export const orderMovingAddressURL = (id: string) => build(ordersURL(), id, 'moving', 'address');
export const confirmOrderURL = (token: string) => build('/orders', token, 'confirm');
export const signedOrderSignaturesURL = () => '/orders/signatures';
export const disposalConfirmationURL = (orderID: string) => build(ordersURL(), orderID, 'disposal_confirmation');
export const movingServicePackageUpdateURL = (orderID: string) =>
  build(ordersURL(), orderID, 'moving_service_package_update');
export const pickupServicePackageUpdateURL = (orderID: string) =>
  build(ordersURL(), orderID, 'pickup_service_package_update');

export const referralURL = (source: string, platform: string) => `/referral?source=${source}&platform=${platform}`;
export const referralsURL = () => '/referrals/histories';

export const billingURL = (tab?: BillingTabs) => build('/billing', tab);
export const invoiceURL = (id: string, action?: string) => build('/invoices', id, action);

export const accountURL = () => '/account';
export const settingsURL = () => '/settings';
export const appointmentURL = () => orderStepURL('new');
export const itemsURL = (status?: ItemStatusSlug | ':status', page?: number | ':page') =>
  build('/items', status ? `status/${status}` : undefined, page ? `page/${page}` : undefined);
export const itemURL = (uuid: string, status: ItemStatusSlug = ItemStatusSlug.Available) =>
  `/items/status/${status}?selectedItem=${uuid}`;
export const payURL = () => '/pay';
export const activateURL = () => '/activate';
export const loginURL = () => '/login';
export const logoutURL = () => '/logout';
export const resetPasswordURL = () => '/password/new';

export enum SupportFlowSlug {
  Standard = 'standard',
  DayOf = 'day_of',
}

export const baseSupportURL = () => '/support';
export const supportURL = (supportFlow?: SupportFlowSlug | ':support_flow') => build(baseSupportURL(), supportFlow);

export const planURL = () => '/plan';
export const planUpdateURL = () => build(planURL(), 'update');
export const planAddURL = (orderID?: string) => build(planURL(), 'add', orderID);
export const downsizeURL = () => '/downsize';
export const reviewURL = () => '/review';
export const reviewThanksURL = () => '/review/thanks';

export const giftCardURL = () => '/gift-card';
export const onboardingIncentiveGCURL = () => build(giftCardURL(), 'welcome-aboard');

export const addressesURL = () => '/addresses';
export const addressEditURL = (addressID?: string) => `/addresses/${addressID || ':addressID'}/edit`;
export const addressNewURL = () => `/addresses/new`;

export const paymentPlansURL = (id?: string) => build('/payment_plans', id);

export const coiURL = () => '/coi';

export const makespaceURL = () => '/makespace';
export const makespaceConnectURL = () => '/makespace/connect';
export const makespaceTransitionURL = () => '/makespace/transition';

export const baseRescheduleOfferURL = () => '/reschedule_offer';
export const rescheduleOfferTokenURL = (token: string) => build(baseRescheduleOfferURL(), token);
export const rescheduleOfferURL = () => build(baseRescheduleOfferURL(), ':token');
