import React, { useState } from 'react';
import QRCode from 'qrcode.react';
import styled from '@emotion/styled';
import { Modal } from '@shared/components/bootstrap';
import { COLORS } from '@clutter/clean';

const StyledTextButton = styled.span`
  cursor: pointer;
  color: ${COLORS.tealPrimary};
  text-decoration: underline;
`;

const QRCodeWrapper = styled.div`
  display: flex;
  justify-content: center;
  margin-top: 20px;
`;

export const MobileQRCodeModal: React.FC = () => {
  const [isOpen, setIsOpen] = useState<boolean>(false);
  const currentURL = window.location.href;

  const openModal = () => setIsOpen(true);
  const closeModal = () => setIsOpen(false);

  return (
    <>
      <StyledTextButton onClick={openModal}>Upload using Phone</StyledTextButton>

      {isOpen && (
        <Modal onClose={closeModal}>
          <Modal.Content>
            <Modal.Header>
              <Modal.Title>Scan this QR Code to open this page on your phone</Modal.Title>
              <Modal.Close close={closeModal} />
            </Modal.Header>
            <Modal.Body>
              <QRCodeWrapper>
                <QRCode value={currentURL} size={256} />
              </QRCodeWrapper>
            </Modal.Body>
          </Modal.Content>
        </Modal>
      )}
    </>
  );
};
