import { Pluralize, Spacer } from '@shared/components/helpers';
import React, { useCallback, useEffect, useRef, useState } from 'react';

import {
  Box,
  COLORS,
  Select,
  Text,
  mq,
  Label,
  Modal,
  Fieldset,
  Radio,
  UnstyledButton,
  SnackbarProvider,
  useSnackbarContext,
  ProgressBar,
  Button,
  TextButton,
} from '@clutter/clean';
import styled from '@emotion/styled';
import { MoverCountModal } from '@portal/components/orders/home/appointment_details/mover_count_modal/mover_count_modal';
import {
  Estimation__AlgorithmName,
  Estimation__CategoryType,
  Estimation__ResourceType,
  EstimationMovingQuoteFragment,
  HydrateVirtualWalkthroughFlowDocument,
  Moving__QuoteCuftSourceEnum,
  Order__Metadata__ItemsRequireMoreThanOneMover as RequireMoreThanOneMoverEnum,
  OrderTypeEnum,
  Status,
  useEstimationCreateItemsMutation,
  useEstimationStatusCreateMutation,
  useItemInventoryFeaturedCategoriesQuery,
  useMovingEstimationsUpdateMutation,
  useMovingQuoteRegenerateWithItemsMutation,
  useEstimationAiClassificationQuery,
  Estimation__AiClassificationStatus,
  useEstimationAiClassificationSkipMutation,
  useEstimationAiClassificationCreateMutation,
} from '@portal/schema';
import { useDebounce } from '@shared/hooks';
import { capitalize } from 'lodash';
import { wt } from '@portal/initializers/wt';
import { Spinner } from '@portal/components/helpers';
import { Message as ErrorMessage } from '@portal/components/shared/error';
import { StepContainer } from './step_container';
import { StepType } from './virtual_walkthrough/data';
import { EstimatorBar } from './virtual_walkthrough/item_inventory/estimator_bar';
import { IneligibleModal } from './virtual_walkthrough/item_inventory/ineligible_modal';
import { ResetButton } from './virtual_walkthrough/item_inventory/reset_button';
import { CounterCard } from './item_inventory/counter_card';
import { SearchBar } from './item_inventory/search_bar';

export const ITEM_INVENTORY_PAGE_NAME = 'portal:virtual_walkthrough:item_inventory';
export const EIGHT_HOURS_IN_SECONDS = 28800;

const statusOptions = [
  {
    id: 'incomplete',
    value: false,
    label: "No, I'll add the rest later",
  },
  {
    id: 'complete',
    value: true,
    label: 'Yes',
  },
];

const SelectedItemsContainer = styled.div`
  display: grid;
  grid-gap: 12px;
  margin: 16px 0 40px;
`;

const Disclaimer = styled(Text.Body)`
  color: ${COLORS.hippo};
  margin: 64px 0 24px;
  text-align: center;
`;

const RadioContainer = styled(Fieldset)`
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  margin-top: 40px;
  margin-bottom: 24px;
`;

const Card = styled.div<{ primary: boolean; disabled: boolean }>`
  width: 100%;
  height: 48px;
  border: ${({ disabled }) => (disabled ? 'none' : `1.5px solid ${COLORS.tealPrimary}`)};
  border-radius: 4px;
  color: ${({ primary, disabled }) => (disabled ? COLORS.hippo : primary ? COLORS.cloud : COLORS.tealPrimary)};
  cursor: pointer;
  background: ${({ primary, disabled }) =>
    disabled ? COLORS.grayBorder : primary ? COLORS.tealPrimary : COLORS.cloud};
  display: flex;
  justify-content: center;
  align-items: center;
`;

const RadioOption = styled.label`
  ${mq({
    width: ['100%', 'calc(50% - 8px)'],
  })}
`;

const PillButton = styled(UnstyledButton)`
  border: 1px solid ${COLORS.grayBorder};
  padding: 8px 16px;
  text-align: center;
  margin: 4px;
  cursor: pointer;
  border-radius: 32px;
  white-space: nowrap;
`;

const PillButtonsContainer = styled.div`
  display: flex;
  margin: 16px 0 40px;

  ${mq({
    overflowX: ['scroll', null, 'initial'],
    flexWrap: ['initial', null, 'wrap'],
  })}
`;

const ItemPill: React.FC<{
  item: Estimation__CategoryType;
  onAddItemCategory(): void;
}> = ({ item, onAddItemCategory }) => {
  const itemName = item.name;

  return (
    <PillButton onClick={onAddItemCategory}>
      <Text color={COLORS.tealBrand}>+</Text>
      {' ' + itemName}
    </PillButton>
  );
};

const buildOtherItemOptions = (otherItemCategories: Estimation__CategoryType[]) => {
  const categories = otherItemCategories.map(({ name }) => ({
    label: name,
    value: name,
  }));
  categories.unshift({ label: '-', value: '' });
  return categories;
};

const buildRequireMoreThanOneMoverOptions = () => {
  const orderedOptions = [
    RequireMoreThanOneMoverEnum.Yes,
    RequireMoreThanOneMoverEnum.No,
    RequireMoreThanOneMoverEnum.NotSure,
  ];
  const options: Array<{
    label: string;
    value: string;
  }> = orderedOptions.map((option) => ({
    label: option.split('_').map(capitalize).join(' '),
    value: option,
  }));
  options.unshift({ label: '-', value: '' });
  return options;
};

const getQuoteEligibility = (showEstimator: boolean, quote?: EstimationMovingQuoteFragment): boolean => {
  const quoteEligible =
    !quote ||
    !showEstimator ||
    (quote.eligibility &&
      typeof quote.estimatedOrderDuration === 'number' &&
      quote.estimatedOrderDuration < EIGHT_HOURS_IN_SECONDS);

  return !!quoteEligible;
};

const UploadsChangedModal: React.FC<{
  isOpen: boolean;
  onClose(): void;
  regenerateFromPhotos: () => void;
}> = ({ isOpen, onClose, regenerateFromPhotos }) => (
  <Modal isOpen={isOpen} includeCloseButton={false} handleModalClose={onClose}>
    <Box padding="24px" width="calc(100vw - 48px)" maxWidth="582px">
      <Box textAlign="center" margin="0 0 16px">
        <Text.Title size="medium">Regenerate from Photos?</Text.Title>
      </Box>
      <Text.Body>
        It looks like you have updated your photo uploads. Would you like to regenerate your inventory list using our AI
        tool?
      </Text.Body>
      <Box.Flex margin="24px 0 0" gap="16px" justifyContent="flex-end" flexDirection={['column', 'row']}>
        <Button kind="secondary" onClick={onClose}>
          No, Leave List As-Is
        </Button>
        <Button kind="destructive" onClick={() => regenerateFromPhotos()}>
          Yes, Regenerate from Photos
        </Button>
      </Box.Flex>
    </Box>
  </Modal>
);

/* increments by 0.65% every 100ms from 0% to 95% (takes about ~15s) */
const useFakeProgress = ({ active, resetToZero }: { active: boolean; resetToZero: boolean }) => {
  const FAKE_PROGRESS_INCREMENT = 0.0065; // i.e. 0.65%
  const FAKE_PROGRESS_LIMIT = 0.95; // i.e. 95%
  const FAKE_PROGRESS_TICK = 100; // ms

  const [progress, setProgress] = useState(0.0);

  useEffect(() => {
    if (active) {
      if (resetToZero) {
        setProgress(0);
      }

      const timer = setInterval(() => {
        setProgress((prevProgress) => {
          if (prevProgress >= FAKE_PROGRESS_LIMIT) {
            clearInterval(timer);
            return FAKE_PROGRESS_LIMIT;
          }
          return prevProgress + FAKE_PROGRESS_INCREMENT;
        });
      }, FAKE_PROGRESS_TICK);

      return () => clearInterval(timer); // Cleanup on unmount
    }
  }, [active, resetToZero]);

  return active ? progress : undefined;
};

const ItemInventory: React.FC<StepType> = (props) => {
  const {
    values: {
      itemsRequireMoreThanOneMover,
      otherItemCategoryName,
      selectedItemCategories,
      movingQuote,
      uploadsChangedSinceLastAIClassification,
    },
    onChange,
    orderID,
    orderType,
    order,
    next,
  } = props;

  const [error, setError] = useState<string | undefined>();

  const [completed, setCompleted] = useState<undefined | boolean>(undefined);
  const [showFinishedModal, setShowFinishedModal] = useState(false);
  const [showQuoteIneligibleModal, setShowQuoteIneligibleModal] = useState(false);
  const [showMoverCountModal, setShowMoverCountModal] = useState(false);
  const [popQuoteModalOnIneligible, setPopQuoteModalOnIneligible] = useState(true);
  const [isUploadsChangedModalOpen, setIsUploadsChangedModalOpen] = useState<boolean>(
    uploadsChangedSinceLastAIClassification,
  );
  const [pendingAIClassificationID, setPendingAIClassificationID] = useState<string | undefined>(undefined);

  const [estimationsUpdate, { loading: estimationsUpdateLoading }] = useMovingEstimationsUpdateMutation();
  const [regenerateQuote, { loading: regenerateQuoteLoading }] = useMovingQuoteRegenerateWithItemsMutation();
  const [createItems, { loading }] = useEstimationCreateItemsMutation();
  const [estimationStatusCreateMutation, { loading: estimationStatusCreateLoading }] =
    useEstimationStatusCreateMutation({
      refetchQueries: [{ query: HydrateVirtualWalkthroughFlowDocument, variables: { orderID } }],
    });

  const [startAIClassification, { loading: startingAIClassification }] = useEstimationAiClassificationCreateMutation({
    variables: { orderID: orderID },
    onCompleted: ({ estimationAiClassificationCreate }) => {
      if (estimationAiClassificationCreate?.aiClassification) {
        setPendingAIClassificationID(estimationAiClassificationCreate.aiClassification.id);
      }
    },
  });

  const [cancelAIClassification, { loading: cancelingAIClassification }] = useEstimationAiClassificationSkipMutation({
    variables: { aiClassificationID: pendingAIClassificationID! },
    onCompleted: () => {
      setPendingAIClassificationID(undefined);
    },
  });

  const { startPolling, stopPolling } = useEstimationAiClassificationQuery({
    variables: { id: pendingAIClassificationID! },
    skip: !pendingAIClassificationID,
    onCompleted: ({ estimationAiClassification }) => {
      if (estimationAiClassification.status === Estimation__AiClassificationStatus.Completed) {
        onChange('selectedItemCategories', estimationAiClassification.estimatedItems);
        setPendingAIClassificationID(undefined);
      } else if (
        estimationAiClassification.status === Estimation__AiClassificationStatus.Failed ||
        estimationAiClassification.status === Estimation__AiClassificationStatus.Skipped
      ) {
        setPendingAIClassificationID(undefined);
      }
    },
  });

  useEffect(() => {
    if (!pendingAIClassificationID) return;

    startPolling(500);
    return () => stopPolling();
  }, [startPolling, stopPolling, pendingAIClassificationID]);

  const { addSnack } = useSnackbarContext();

  const { data: featuredItems } = useItemInventoryFeaturedCategoriesQuery();
  const debouncedCategories = useDebounce(selectedItemCategories);
  const sanitizedCategories = debouncedCategories?.filter((item) => item.quantity > 0);

  const quoteInitializedRef = useRef(false);
  const pendingExecutionRef = useRef(false);
  const flatRateAmount = order.movingPricingSummary?.flatRateAmount;

  const showEstimator = !order.multiDay && !flatRateAmount;

  const fetchQuote = useCallback(async () => {
    if (orderType === OrderTypeEnum.Move) {
      const { data: quoteData } = await regenerateQuote({
        variables: {
          estimationInput: {
            resourceType: Estimation__ResourceType.Order,
            resourceID: orderID,
            algorithmName: Estimation__AlgorithmName.VirtualWalkthrough,
            otherItemCategoryName: otherItemCategoryName,
            itemsRequireMoreThanOneMover: itemsRequireMoreThanOneMover as RequireMoreThanOneMoverEnum,
            itemInputs: sanitizedCategories!.map((c) => ({
              categoryID: c.category.id,
              quantity: c.quantity,
            })),
          },
        },
      });

      const quote = quoteData?.movingQuoteRegenerateWithItems?.movingQuote;

      if (quote) {
        onChange('movingQuote', quote);

        if (getQuoteEligibility(showEstimator, quote)) {
          setPopQuoteModalOnIneligible(true);
        } else if (popQuoteModalOnIneligible) {
          setPopQuoteModalOnIneligible(false);
          setShowQuoteIneligibleModal(quote.cuftSource === Moving__QuoteCuftSourceEnum.Items);
        }
      }
    }
  }, [
    onChange,
    regenerateQuote,
    setPopQuoteModalOnIneligible,
    setShowQuoteIneligibleModal,
    orderID,
    orderType,
    otherItemCategoryName,
    itemsRequireMoreThanOneMover,
    sanitizedCategories,
    popQuoteModalOnIneligible,
    showEstimator,
  ]);

  // Auto-generate items, if none have been added
  useEffect(() => {
    if (selectedItemCategories === undefined || selectedItemCategories.length === 0) {
      startAIClassification();
    }
  }, []);

  // Indicate that we want to regenerate a quote any time important input changes
  useEffect(() => {
    if (quoteInitializedRef.current) {
      pendingExecutionRef.current = true;
    }
  }, [quoteInitializedRef, otherItemCategoryName, itemsRequireMoreThanOneMover, debouncedCategories]);

  // Fetch a quote any time there are changes AND the regenerateQuote
  // mutation is not currently executing
  useEffect(() => {
    if (pendingExecutionRef.current && !regenerateQuoteLoading) {
      pendingExecutionRef.current = false;
      fetchQuote();
    }
  }, [fetchQuote, pendingExecutionRef, regenerateQuoteLoading]);

  // Fetch a quote onMount to make sure quote is up to date with estimated items
  useEffect(() => {
    fetchQuote();
    quoteInitializedRef.current = true;
  }, []);

  const aiClassificationRunning = startingAIClassification || !!pendingAIClassificationID || cancelingAIClassification;
  const aiClassificationProgress = useFakeProgress({
    active: aiClassificationRunning,
    resetToZero: startingAIClassification,
  });

  useEffect(() => {
    if (aiClassificationRunning) {
      onChange('uploadsChangedSinceLastAIClassification', false);
      setIsUploadsChangedModalOpen(false);
    }
  }, [aiClassificationRunning]);

  if (!featuredItems) {
    return <Spinner />;
  }

  if (aiClassificationProgress) {
    return (
      <Box.Flex textAlign={'center'} flexDirection={'column'}>
        <Text.Title size="small">Generating Your Estimated Item List</Text.Title>
        <ProgressBar percentage={aiClassificationProgress} />
        {pendingAIClassificationID && !cancelingAIClassification && aiClassificationProgress > 0.5 && (
          <>
            <Spacer height="32px" />

            <TextButton onClick={() => cancelAIClassification()}>Skip AI Classification</TextButton>
          </>
        )}
      </Box.Flex>
    );
  }

  const featuredItemCategories = featuredItems?.virtualWalkthroughItemCategories;
  const otherItemCategories = featuredItems?.virtualWalkthroughOtherItemCategories;

  const onAddItem = (category: Estimation__CategoryType) => {
    if (selectedItemCategories?.findIndex((c) => c.category.id === category.id) === -1) {
      onChange('selectedItemCategories', [{ category: category, quantity: 1 }, ...(selectedItemCategories || [])]);
    }
  };

  const onNext = async () => {
    const input = {
      resourceType: Estimation__ResourceType.Order,
      resourceID: orderID,
      algorithmName: Estimation__AlgorithmName.VirtualWalkthrough,
      otherItemCategoryName: otherItemCategoryName,
      itemsRequireMoreThanOneMover: itemsRequireMoreThanOneMover as RequireMoreThanOneMoverEnum,
      itemInputs: sanitizedCategories!.map((c) => ({
        categoryID: c.category.id,
        quantity: c.quantity,
      })),
    };

    const { data: createItemsData } = await createItems({
      variables: { input: input },
    });

    if (createItemsData) {
      setShowFinishedModal(true);
    }
  };

  const quoteEligible = getQuoteEligibility(showEstimator, movingQuote);

  const canNext =
    !loading &&
    !regenerateQuoteLoading &&
    quoteEligible &&
    ((orderType !== OrderTypeEnum.Move && !!itemsRequireMoreThanOneMover && !!otherItemCategoryName) ||
      orderType === OrderTypeEnum.Move) &&
    !!selectedItemCategories?.length;

  const onSelectStatus = async (finishedAddingItems: boolean) => {
    setError(undefined);
    setCompleted(finishedAddingItems);

    const { data: estimationStatusData } = await estimationStatusCreateMutation({
      variables: {
        input: {
          orderID: orderID,
          status: finishedAddingItems,
        },
      },
    });

    const updateEstimations = movingQuote && (finishedAddingItems || movingQuote.estimatedCuft > order.estimatedCuft);
    const estimationsUpdateResponse = updateEstimations
      ? await estimationsUpdate({
          variables: {
            input: {
              orderID,
              quoteID: movingQuote.id,
            },
          },
        })
      : undefined;

    if (
      estimationStatusData &&
      (!estimationsUpdateResponse || estimationsUpdateResponse.data?.movingEstimationsUpdate?.status === Status.Ok)
    ) {
      next();
    } else {
      setShowFinishedModal(false);
      setError('An unknown error occurred');
    }
  };

  const onAdjustItemCount = (category: Estimation__CategoryType, quantity: number | null) => {
    if (!selectedItemCategories) return;

    const categoryIndex = selectedItemCategories?.findIndex((c) => c.category === category);

    if (quantity === null) {
      onChange(
        'selectedItemCategories',
        selectedItemCategories.filter((_, i) => i !== categoryIndex),
      );
    } else {
      const updatedSelections = selectedItemCategories.map((c, i) => {
        if (i === categoryIndex) {
          return { ...c, quantity };
        }
        return c;
      });
      onChange('selectedItemCategories', updatedSelections);
    }
  };

  const pills =
    featuredItemCategories?.filter(
      (category: Estimation__CategoryType) => !selectedItemCategories?.find((item) => item.category.id === category.id),
    ) ?? [];

  return (
    <StepContainer
      {...props}
      canPrev={true}
      neverFullWidthNext={true}
      canNext={canNext}
      next={() => onNext()}
      loading={regenerateQuoteLoading}
    >
      {error && <ErrorMessage message={error} />}
      <Text.Title size="medium">
        Update and confirm items you’re {orderType === OrderTypeEnum.Move ? 'moving' : 'storing'}
      </Text.Title>
      <Text.Body>
        The more accurate your inventory list, the better we can build the right team for your appointment.
      </Text.Body>
      <SearchBar
        selectedItemCategories={selectedItemCategories ?? []}
        onSelectedItemsChange={(items) => onChange('selectedItemCategories', items)}
      />
      <PillButtonsContainer>
        {pills.map((item, i) => (
          <ItemPill key={i} item={item} onAddItemCategory={() => onAddItem(item)} />
        ))}
      </PillButtonsContainer>
      {selectedItemCategories && selectedItemCategories.length > 0 && (
        <>
          <Box.Flex justifyContent="space-between" alignItems="center">
            <Label>Your added items</Label>
            <ResetButton
              items={sanitizedCategories}
              createItems={(items) => onChange('selectedItemCategories', items)}
              regenerateFromPhotos={startAIClassification}
            />
          </Box.Flex>
          <SelectedItemsContainer>
            {Object.values(selectedItemCategories).map((selection, i) => (
              <CounterCard
                key={i}
                item={selection.category}
                count={selection.quantity || 0}
                onChange={(count) => onAdjustItemCount(selection.category, count)}
              />
            ))}
          </SelectedItemsContainer>
          {orderType !== OrderTypeEnum.Move && (
            <>
              <Label>How many additional items do you plan to move that were not found in our search tool?</Label>
              <Box margin="16px 0 40px">
                <Select
                  name="other_items"
                  options={buildOtherItemOptions(otherItemCategories)}
                  value={otherItemCategoryName}
                  onChange={(value: any) => onChange('otherItemCategoryName', value)}
                />
              </Box>
              <Label>Do any of your items require more than 1 person to carry?</Label>
              <Box margin="16px 0 0">
                <Select
                  name="items_require_more_than_one_mover"
                  options={buildRequireMoreThanOneMoverOptions()}
                  value={itemsRequireMoreThanOneMover}
                  onChange={(value: any) => onChange('itemsRequireMoreThanOneMover', value)}
                />
              </Box>
            </>
          )}
        </>
      )}
      <Disclaimer>Please complete your inventory list at least 48 hours before your appointment.</Disclaimer>
      {movingQuote && showEstimator && (
        <EstimatorBar
          orderID={orderID}
          whiteGloveTestEligible={order.permissions.whiteGloveTestEligible}
          quote={movingQuote}
          onCustomize={() => setShowMoverCountModal(true)}
        />
      )}
      {showMoverCountModal && showEstimator && (
        <MoverCountModal
          pageName={ITEM_INVENTORY_PAGE_NAME}
          hideModal={() => {
            setShowMoverCountModal(false);
          }}
          order={order}
          onUpdateMoverCount={(quote) => {
            const oldQuoteValue = movingQuote?.moverSelection ?? 0;
            const newQuoteValue = quote?.moverSelection ?? 0;
            const difference = newQuoteValue - oldQuoteValue;

            addSnack({
              key: 'mover_count_updated',
              content: (
                <>
                  <Pluralize count={Math.abs(difference)} singular="mover" plural="movers" />{' '}
                  {difference > 0 ? 'added' : 'removed'} and hourly rate updated
                </>
              ),
            });
            onChange('movingQuote', quote);
          }}
        />
      )}
      <UploadsChangedModal
        isOpen={isUploadsChangedModalOpen}
        onClose={() => setIsUploadsChangedModalOpen(false)}
        regenerateFromPhotos={startAIClassification}
      />
      {showFinishedModal && (
        <Modal isOpen={showFinishedModal} handleModalClose={() => setShowFinishedModal(false)} includeCloseButton>
          <Box margin="64px 24px 32px" maxWidth="600px" minWidth="320px" textAlign="center">
            <Text.Title size="medium">Are you finished adding items?</Text.Title>
            <Text.Body>
              <b>Let us know if you have finished adding everything you plan to move.</b> This helps us plan accordingly
              to ensure we schedule enough time and movers to complete your appointment.
            </Text.Body>
            <RadioContainer>
              {statusOptions.map((option) => (
                <RadioOption key={option.id}>
                  <Radio.Input
                    id={option.id}
                    checked={option.value === completed}
                    onChange={() => onSelectStatus(option.value)}
                    disabled={estimationsUpdateLoading || estimationStatusCreateLoading}
                  />
                  <Card
                    primary={option.id === 'complete'}
                    disabled={estimationsUpdateLoading || estimationStatusCreateLoading}
                  >
                    {option.label}
                  </Card>
                </RadioOption>
              ))}
            </RadioContainer>
            <Box color={COLORS.hippo} margin="8px 0 0">
              <Text.Body>Please complete your inventory list at least 48 hours before your appointment.</Text.Body>
            </Box>
          </Box>
        </Modal>
      )}
      {orderType === OrderTypeEnum.Move && showEstimator && (
        <IneligibleModal
          isOpen={showQuoteIneligibleModal}
          movers={movingQuote?.moverSelection ?? 0}
          orderID={orderID}
          onClose={() => {
            wt.track({
              action: 'click',
              objectName: 'close_button',
              objectType: 'button',
              pageName: ITEM_INVENTORY_PAGE_NAME,
              container: 'ineligible_modal',
              label: 'No thanks',
              order_id: orderID,
            });
            setShowQuoteIneligibleModal(false);
          }}
          onUpgrade={() => {
            setShowQuoteIneligibleModal(false);
            setShowMoverCountModal(true);
          }}
        />
      )}
    </StepContainer>
  );
};

const WrappedItemInventory: React.FC<StepType> = (props) => (
  <SnackbarProvider bottom="208px">
    <ItemInventory {...props} />
  </SnackbarProvider>
);

export { WrappedItemInventory as ItemInventory };
