import { DateTime } from 'luxon';
import React, { useState } from 'react';

import {
  Account__TermCommitment,
  InventoryItemFragment,
  Maybe,
  OrderSubtypeEnum,
  ReturnInventoryOrderFragment as Order,
  ReturnInventoryOrderServiceFragment as OrderService,
  useOrderClassifyQuery,
  useReturnInventoryQuery,
} from '@portal/schema';

import { Inventory } from '@portal/components/helpers/inventory';
import { Actions } from '@portal/components/helpers/inventory/actions';
import { Filters, Mode } from '@portal/components/helpers/inventory/filters';
import { ReturnInventory, isSelected } from '@portal/components/return_inventory';
import { IReturnInventoryInput } from '@portal/types';
import { Alert, Spacing } from '@shared/components/bootstrap';
import { useDebounce } from '@shared/hooks';
import { fulfilledTermCommitment } from '@shared/utils/fulfilled_term_commitment';

import { AccountCancelReasonModal } from './account_cancel_reason_modal';
import { PerItemOrderFooter } from '../details/per_item_order_footer';
import { Button } from '../steps/base';
import { TermCommitmentModal } from './term_commitment_modal';

const SubtypeMessage: React.FC<{
  subtype: OrderSubtypeEnum;
  newSubtype?: OrderSubtypeEnum;
}> = ({ subtype, newSubtype }) => {
  if (!subtype || newSubtype === subtype) {
    return null;
  }

  let message;
  if (newSubtype === OrderSubtypeEnum.Final) {
    message = 'Since you have requested all your items, your account will be canceled.';
  } else if (newSubtype === OrderSubtypeEnum.Subsequent) {
    message = 'Since you have not requested all your items, your account will remain open.';
  }

  return <Alert style="info">{message}</Alert>;
};

export const OrderReturnInventoryForm: React.FC<{
  updating: boolean;
  order: Order;
  service: OrderService;
  termCommitment?: Maybe<Account__TermCommitment>;
  onSubmit(input: IReturnInventoryInput): void;
}> = ({ updating, order, service, termCommitment, onSubmit }) => {
  const [allItems, setAllItems] = useState<InventoryItemFragment[] | undefined>(undefined);
  const [selections, setSelections] = useState<InventoryItemFragment[]>(service.items);
  const [query, setQuery] = useState<string | undefined>(undefined);
  const [mode, setMode] = useState<Mode>(Mode.Selected);
  const [showTermCommitmentModal, setShowTermCommitmentModal] = useState<boolean>(false);
  const [showAccountCancelReasonModal, setShowAccountCancelReasonModal] = useState<boolean>(false);
  const selectedIDs = new Set(selections.map(({ id }) => id));

  const { data: requestable, loading: fetching } = useReturnInventoryQuery({
    variables: {
      orderID: service.id,
      query: useDebounce(query),
    },
    onCompleted: (data) => {
      if (!allItems) {
        setAllItems(
          data.inventory.reduce((acc, item) => {
            if (item.__typename === 'Item') {
              acc.push(item);
            } else if (item.__typename === 'ItemGroup') {
              acc = acc.concat(item.items);
            }
            return acc;
          }, [] as InventoryItemFragment[]),
        );
      }
    },
  });

  const inventory = requestable?.inventory;
  const customerSelectedItemCount = inventory?.filter((item) => isSelected(item, selectedIDs)).length;

  const { data } = useOrderClassifyQuery({
    variables: {
      orderID: service.id,
      cancelable: allItems ? allItems.every((item) => selectedIDs.has(item.id)) : false,
    },
    skip: !allItems,
  });

  const loading = fetching || updating;

  const onFormSubmit = (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    event.stopPropagation();
    if (loading || !selectedIDs.size) {
      return;
    }

    const newSubtype = data?.classify?.subtype;

    if (!fulfilledTermCommitment(DateTime.fromISO(order.scheduled), newSubtype, termCommitment)) {
      setShowTermCommitmentModal(true);
      return;
    } else if (service.subtype !== newSubtype && newSubtype === OrderSubtypeEnum.Final) {
      setShowAccountCancelReasonModal(true);
      return;
    }

    onSubmit({ itemIDs: Array.from(selectedIDs) });
  };

  const onCancelReasonSubmitted = () => {
    if (loading || !selectedIDs.size) {
      return;
    }
    onSubmit({ itemIDs: Array.from(selectedIDs) });
  };

  return (
    <>
      {showTermCommitmentModal && (
        <TermCommitmentModal
          termCommitment={termCommitment!}
          scheduled={DateTime.fromISO(order.scheduled)}
          onClose={() => setShowTermCommitmentModal(false)}
        />
      )}
      {showAccountCancelReasonModal && (
        <AccountCancelReasonModal
          orderID={order.id}
          onClose={() => setShowAccountCancelReasonModal(false)}
          onSubmitted={onCancelReasonSubmitted}
        />
      )}
      <form onSubmit={onFormSubmit}>
        <SubtypeMessage subtype={service.subtype} newSubtype={data?.classify?.subtype} />
        <Spacing mb={3}>
          <Filters all={inventory?.length} selected={customerSelectedItemCount} mode={mode} onChange={setMode} />
        </Spacing>
        <Spacing mb={3}>
          <Inventory.Search loading={loading} query={query} onSearch={setQuery} />
        </Spacing>
        <Spacing mb={3}>
          <Actions
            items={allItems}
            selectedIDs={selectedIDs}
            onChange={setSelections}
            customerItemCount={inventory?.length}
          />
        </Spacing>
        <ReturnInventory
          disableShippingTag
          mode={mode}
          inventory={inventory || []}
          selections={selections}
          onChange={setSelections}
        />
        <SubtypeMessage subtype={service.subtype} newSubtype={data?.classify?.subtype} />
        <PerItemOrderFooter orderID={order.id} orderEdits={{ itemIDs: Array.from(selectedIDs) }}>
          <Button type="button" className="secondary" onClick={() => setSelections(service.items)}>
            Reset
          </Button>
          <Button type="submit" className="primary" disabled={!selectedIDs.size || loading}>
            Save Changes
          </Button>
        </PerItemOrderFooter>
      </form>
    </>
  );
};
