import React from 'react';

import styled from '@emotion/styled';

import { Box, Button, COLORS, FontWeight, mq, Text, TextButton } from '@clutter/clean';

import mostPopularBadge from '@portal/images/most_popular.svg';
import { Currency } from '@shared/components/helpers';
import { useBreakpointFlags } from '@portal/utils';
import { ValueProps } from './value_props';

const MostPopularBadge = styled.img`
  position: absolute;
  right: 8px;
  width: 44px;

  ${mq({
    top: ['0px', null, null, '4px'],
  })}
`;

const CTAContainer = styled.div`
  display: flex;
  border-top: 1px solid ${COLORS.grayBorder};
  padding: 12px 16px;
  align-items: center;
  justify-content: space-between;
`;

const ValuePropIcon = styled.img`
  max-height: 32px;
  margin-right: 8px;
`;

const Grid = styled.div`
  display: grid;
  grid-template-columns: 1fr 4fr;
  align-items: center;
  justify-items: center;
  gap: 8px;
  min-height: 34px;
`;

const Description = styled(Text.Caption)`
  text-align: left;
  width: 100%;
`;

const Container = styled.div<{ selected: boolean }>`
  display: flex;
  cursor: pointer;
  flex-direction: column;
  justify-content: space-between;
  border-radius: 12px;
  overflow: hidden;
  background: ${({ selected }) => (selected ? COLORS.tealBackground : COLORS.cloud)};
  border: ${({ selected }) => (selected ? `2px solid ${COLORS.tealBrand}` : `1px solid ${COLORS.grayBorder}`)};

  ${mq({
    width: ['100%', null, null, '236px'],
  })}
`;

const StyledButton = styled(Button)`
  ${mq({
    minWidth: ['124px', null, null, 'unset'],
  })}
`;

const Caption = styled(Text.Caption)`
  ${mq({
    fontSize: ['14px', '12px'],
  })}
`;

const Callout = styled(Text.Callout)`
  ${mq({
    fontSize: ['16px', '14px'],
  })}
`;

const Banner: React.FC<{
  value: string;
  selected: boolean;
  showBadge: boolean;
}> = ({ value, selected, showBadge }) => (
  <Box background={selected ? COLORS.tealPrimary : COLORS.tealBackground} padding="4px 16px" position="relative">
    {showBadge && <MostPopularBadge src={mostPopularBadge} />}
    <Text.Caption color={selected ? COLORS.cloud : COLORS.tealPrimary} weight={FontWeight.Medium}>
      {value}
    </Text.Caption>
  </Box>
);

const LaborRate: React.FC<{
  flatRate?: number;
  laborRate: number;
  minimumHours: number;
}> = ({ flatRate, laborRate, minimumHours }) => (
  <Box.Flex
    flexDirection={['column', null, null, 'row']}
    justifyContent="space-between"
    alignItems="flex-end"
    margin="0 0 12px"
  >
    <Callout color={COLORS.tealPrimary} weight={FontWeight.Medium}>
      <Currency value={flatRate || laborRate} suffix={flatRate ? ' flat rate' : '/hour'} precision={0} />
    </Callout>
    {!flatRate && minimumHours > 0 && (
      <Caption color={COLORS.hippo} weight={FontWeight.Medium}>
        {minimumHours}hr min.
      </Caption>
    )}
  </Box.Flex>
);

const CardValueProps: React.FC<{ valueProps: ValueProps }> = ({ valueProps }) => {
  const { isMobile } = useBreakpointFlags();
  const values = isMobile ? valueProps.values.slice(0, 2) : valueProps.values;

  return (
    <Box.Flex flexDirection="column" gap="16px">
      <Text.Caption weight={FontWeight.Medium} color={COLORS.hippo}>
        {valueProps.title}
      </Text.Caption>
      <Box.Grid
        gridGap="16px"
        gridTemplateRows={['unset', null, null, '1fr']}
        gridTemplateColumns={['1fr 1fr', null, null, 'unset']}
      >
        {values.map((value, i) => (
          <Grid key={i}>
            <ValuePropIcon src={value.icon} />
            <Description color={COLORS.hippo}>{value.description}</Description>
          </Grid>
        ))}
      </Box.Grid>
    </Box.Flex>
  );
};

export const Card: React.FC<{
  bannerValue: string;
  showPrice: boolean;
  title: string;
  subtitle: string;
  selected: boolean;
  testId: string;
  laborRate?: number;
  flatRate?: number;
  minimumHours: number;
  valueProps: ValueProps;
  showBadge?: boolean;
  onCompare(): void;
  onSelect(): void;
}> = ({
  bannerValue,
  showPrice,
  title,
  subtitle,
  selected,
  testId,
  laborRate,
  flatRate,
  minimumHours,
  valueProps,
  showBadge,
  onCompare,
  onSelect,
}) => (
  <Container selected={selected} onClick={onSelect} data-test-id={testId}>
    <Banner value={bannerValue} selected={selected} showBadge={!!showBadge} />
    <Box.Flex flexDirection="column" padding="12px 16px" height="100%">
      <Box.Flex
        flexDirection={['row', null, null, 'column']}
        alignItems={['center', null, null, 'unset']}
        gap={['12px', null, null, 'unset']}
        justifyContent="space-between"
      >
        <Box.Flex flexDirection="column" margin="0 0 8px">
          <Text.Title size="extraSmall">{title}</Text.Title>
          <Caption color={COLORS.hippo}>{subtitle}</Caption>
        </Box.Flex>
        {laborRate && showPrice && <LaborRate flatRate={flatRate} laborRate={laborRate} minimumHours={minimumHours} />}
      </Box.Flex>
      <CardValueProps valueProps={valueProps} />
    </Box.Flex>

    <CTAContainer>
      <Box
        padding="8px"
        onClick={(e) => {
          e.stopPropagation();
          onCompare();
        }}
      >
        <TextButton>COMPARE</TextButton>
      </Box>
      <StyledButton
        onClick={(e) => {
          e.stopPropagation();
          onSelect();
        }}
        size="small"
        kind={selected ? 'primary' : 'secondary'}
      >
        {selected ? 'Selected' : 'Select'}
      </StyledButton>
    </CTAContainer>
  </Container>
);
