import React, { useContext, useRef, useState } from 'react';
import { Box, Button, COLORS, Modal, mq, Text } from '@clutter/clean';
import styled from '@emotion/styled';

import {
  EstimationUploadFragmentDoc,
  OrderTypeEnum,
  useEstimationUploadCreateMutation,
  useEstimationUploadDestroyMutation,
  useEstimationUploadsQuery,
} from '@portal/schema';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Manager, Selector, Context as ManagerContext } from '@shared/components/files';
import {
  ImageThumbnail,
  MediaUploader,
  RemoveButton,
  Upload,
  VideoThumbnail,
} from '@portal/components/shared/media_uploader';
import { TrackedClick } from '@portal/components/wt/tracked_click';
import { client } from '@portal/libraries/apollo';
import { useBreakpointFlags } from '@portal/utils';

import example_images_desktop from '@portal/images/vw_example_desktop.png';
import example_images_mobile from '@portal/images/vw_example_mobile.png';
import { wt } from '@portal/initializers/wt';
import { StepContainer } from './step_container';
import { GettingStartedModal } from './virtual_walkthrough/getting_started_modal';
import { StepType } from './virtual_walkthrough/data';
import { MobileQRCodeModal } from './virtual_walkthrough/mobile_qr_code_modal';

// WTEventTracking Helpers
function trackingParams({ orderID, objectName, label }: { orderID?: string; objectName?: string; label: string }) {
  return {
    pageName: 'portal:virtual_walkthrough:photos',
    container: 'virtual_walkthrough',
    action: 'click',
    objectType: 'button',
    objectName: objectName,
    label: label,
    order_id: orderID,
  };
}

const StyledFlexBox = styled.div`
  display: flex;
  gap: 24px;
  flex-direction: column;
`;

const FileContainer = styled.div`
  width: 100%;
  min-height: 120px;
`;

export const StyledGrid = styled.div`
  display: grid;
  grid-column-gap: 12px;
  grid-row-gap: 12px;
  justify-content: start;
  grid-template-columns: repeat(auto-fit, 100px);
`;

const StyledLabel = styled.label`
  max-width: 100px;
  margin-bottom: 0;
  cursor: pointer;
`;

export const StyledButton = styled(Button)`
  margin-bottom: 16px;
  height: 100px;
  width: 100px;
  max-height: 100px;
  max-width: 100px;
  min-width: unset;
  padding: 0;
  background: transparent;
  color: ${COLORS.tealPrimary};
  cursor: pointer;
`;

const Container = styled.div`
  ${mq({
    padding: ['52px 16px 32px', null, '64px 24px 32px'],
  })}
`;

const StyledImage = styled.img`
  width: 100%;

  ${mq({
    minWidth: ['300px', null, '500px'],
  })}
`;

const StyledTextButton = styled.span`
  cursor: pointer;
  color: ${COLORS.tealPrimary};
  text-decoration: underline;
`;

const useUploadedForOrderID = (orderID: string) => {
  const { data: estimationUploadData } = useEstimationUploadsQuery({
    client,
    variables: { orderID },
  });

  return estimationUploadData?.estimationUploads ?? [];
};

const PhotosContent: React.FC<
  StepType & {
    uploading: boolean;
  }
> = ({ uploading, ...props }) => {
  const {
    next,
    orderID,
    order,
    onChange,
    values: { selectedItemCategories },
  } = props;

  const uploaded = useUploadedForOrderID(orderID);

  const { uploads, saving } = useContext(ManagerContext);
  const labelRef = useRef<HTMLLabelElement>(null);

  const flatRateAmount = order.movingPricingSummary?.flatRateAmount;

  const [showGettingStartedModal, setShowGettingStartedModal] = useState<boolean>(selectedItemCategories?.length === 0);

  const onSeeExamples = () => {
    setShowModal(true);
    const params = trackingParams({ label: 'See examples' });
    wt.track(params);
  };

  const [showModal, setShowModal] = useState(false);
  const { isMobile } = useBreakpointFlags();

  const [destroyUpload, { loading: destroying }] = useEstimationUploadDestroyMutation({
    client,
    update(cache, { data }) {
      const deleted = data?.estimationUploadDestroy?.upload;
      if (deleted) {
        cache.evict({ id: cache.identify(deleted) });
        onChange('uploadsChangedSinceLastAIClassification', true);
      }
    },
  });

  return (
    <>
      <StepContainer {...props} loading={uploading || saving || destroying} next={next}>
        <StyledFlexBox>
          <Modal includeCloseButton isOpen={showModal} handleModalClose={() => setShowModal(false)}>
            <Container>
              <Box textAlign="center" padding="0 0 8px 0" color={COLORS.tealDark}>
                <Text.Title size="small">Examples of useful photos</Text.Title>
              </Box>
              <StyledImage src={isMobile ? example_images_mobile : example_images_desktop} />
            </Container>
          </Modal>
          <Text.Title size="medium">Upload Photos</Text.Title>
          <Text.Body>
            Upload photos of what is being {order.type === OrderTypeEnum.Move ? 'moved' : 'stored'}. Clutter’s AI will
            use photos to generate an expected inventory list.{' '}
            <StyledTextButton onClick={onSeeExamples}>See example photos</StyledTextButton>
          </Text.Body>
          {!isMobile && <MobileQRCodeModal />}
          <FileContainer>
            <StyledGrid>
              <TrackedClick
                params={trackingParams({ orderID: orderID, objectName: 'upload_item', label: 'Upload Item' })}
              >
                <StyledButton
                  kind={uploaded.length > 0 ? 'secondary' : 'primary'}
                  onClick={() => labelRef?.current?.click()}
                >
                  <StyledLabel onClick={(event) => event.stopPropagation()} ref={labelRef}>
                    <FontAwesomeIcon icon="plus" /> <br />
                    Photo
                    <Selector multiple={true} accept="image/*" />
                  </StyledLabel>
                </StyledButton>
              </TrackedClick>
              {uploaded.map((entry) => (
                <Upload key={entry.id}>
                  <RemoveButton
                    aria-label="Delete"
                    onClick={async () => {
                      await destroyUpload({ variables: { orderID, uploadID: entry.id } });
                    }}
                  >
                    <FontAwesomeIcon icon="times" />
                  </RemoveButton>
                  {entry.media.isImage && <ImageThumbnail src={entry.media.imgixURL + '?w=200&h=200'} />}
                  {entry.media.isVideo && <VideoThumbnail src={entry.media.imgixURL + '?w=200&h=200'} />}
                </Upload>
              ))}
              {uploads.map((entry) => (
                <MediaUploader key={entry.uuid} {...entry} />
              ))}
            </StyledGrid>
          </FileContainer>
        </StyledFlexBox>
      </StepContainer>
      {showGettingStartedModal && (
        <GettingStartedModal
          orderID={orderID}
          orderScheduled={order.scheduled}
          hideModal={() => setShowGettingStartedModal(false)}
          flatRate={!!flatRateAmount}
          moving={order.type === OrderTypeEnum.Move}
        />
      )}
    </>
  );
};

export const Photos: React.FC<StepType> = (props) => {
  const { orderID, onChange } = props;

  const [createUpload, { loading: saving }] = useEstimationUploadCreateMutation({
    client,
    update(cache, { data }) {
      const newUpload = data?.estimationUploadCreate?.upload;
      if (!newUpload) return;
      cache.modify({
        fields: {
          estimationUploads(existing = []) {
            const newUploadFragment = cache.writeFragment({
              data: newUpload,
              fragment: EstimationUploadFragmentDoc,
            });
            return [...existing, newUploadFragment];
          },
        },
      });
      onChange('uploadsChangedSinceLastAIClassification', true);
    },
  });

  const onUploaded = async (file: File, signedID: string) => {
    await createUpload({ variables: { orderID, signedID } });
  };

  return (
    <Manager onSave={onUploaded} saving={saving}>
      {({ uploading }) => <PhotosContent {...props} uploading={uploading} />}
    </Manager>
  );
};
