import { DateTime, Duration } from 'luxon';
import React, { useState } from 'react';
import { RouteComponentProps } from 'react-router-dom';

import { Spinner } from '@portal/components/helpers';
import { orderURL } from '@portal/config/routes';
import {
  Maybe,
  Status,
  useOrderFormRescheduleQuery,
  useRescheduleOrderMutation,
  Account__CancelIntent__Reason as AccountCancelIntentReason,
  OrderStatusEnum,
} from '@portal/schema';
import { FlashKind } from '@shared/types';
import { fulfilledTermCommitment } from '@shared/utils/fulfilled_term_commitment';

import { Container } from './container';
import { OrderRescheduleForm } from './reschedule/form';
import { TermCommitmentModal } from './reschedule/term_commitment_modal';

const SUCCESS_MESSAGE = 'Order has been successfully updated.';
const UNABLE_TO_RESCHEDULE_MESSAGE =
  'This order can no longer be rescheduled. If you are no longer available then please cancel this order or schedule a new order.';

export type PayAndRescheduleInput = {
  scheduled: DateTime;
  duration: Duration;
  laborRateID?: string;
  perMoverHourAdjustmentAmount?: number;
};

export const Reschedule: React.FC<RouteComponentProps<{ orderID: string }>> = ({
  match: {
    params: { orderID },
  },
  history,
}) => {
  const [error, setError] = useState<Maybe<string>>();
  const [showTermCommitmentModal, setShowTermCommitmentModal] = useState<boolean>(false);
  const [payAndRescheduleInput, setPayAndRescheduleInput] = useState<PayAndRescheduleInput>();

  const { data, loading } = useOrderFormRescheduleQuery({ variables: { orderID } });
  const [reschedule, { loading: rescheduling }] = useRescheduleOrderMutation();

  const onSubmit = async (
    scheduled: DateTime,
    duration: Duration,
    laborRateID?: string,
    perMoverHourAdjustmentAmount?: number,
    overrideTermCommitment?: boolean,
  ) => {
    if (!overrideTermCommitment && !fulfilledTermCommitment(scheduled, order.subtype, account.termCommitment)) {
      setPayAndRescheduleInput({ scheduled, duration, laborRateID, perMoverHourAdjustmentAmount });
      setShowTermCommitmentModal(true);
      return;
    }

    setError(undefined);
    const response = await reschedule({
      variables: {
        input: {
          orderID,
          scheduled: scheduled.toJSON(),
          duration: duration.toJSON(),
          laborRateID,
          perMoverHourAdjustmentAmount,
        },
      },
    });

    if (response?.data?.rescheduleOrder) {
      if (response.data.rescheduleOrder.status === Status.Unprocessable) {
        setError(response.data.rescheduleOrder.error);
      } else {
        history.push(orderURL(orderID), {
          flash: {
            kind: FlashKind.Success,
            message: SUCCESS_MESSAGE,
          },
        });
      }
    }
  };

  const onPayAndReschedule = async () => {
    const overrideTermCommitment = true;
    const { scheduled, duration, laborRateID, perMoverHourAdjustmentAmount } = payAndRescheduleInput!;

    onSubmit(scheduled, duration, laborRateID, perMoverHourAdjustmentAmount, overrideTermCommitment);
  };

  if (loading || !data) {
    return <Spinner />;
  }

  const { order, account } = data;

  if (!order.permissions.reschedulable) {
    history.push(orderURL(orderID), {
      flash: {
        kind: FlashKind.Danger,
        message: UNABLE_TO_RESCHEDULE_MESSAGE,
      },
    });
  }

  if (order.accountCancelIntent?.reason === AccountCancelIntentReason.PayToVacate) {
    return null;
  }

  const unconfirmed = order.status === OrderStatusEnum.Pending;

  return (
    <>
      {showTermCommitmentModal && (
        <TermCommitmentModal
          termCommitment={account.termCommitment!}
          scheduled={DateTime.fromISO(order.scheduled)}
          onClose={() => setShowTermCommitmentModal(false)}
          onTermCommitmentPayment={onPayAndReschedule}
        />
      )}
      <Container
        breadcrumb="Reschedule"
        orderID={order.id}
        scheduled={order.scheduled}
        error={error}
        unconfirmed={unconfirmed}
      >
        <OrderRescheduleForm order={order} loading={rescheduling} onSubmit={onSubmit} />
      </Container>
    </>
  );
};
