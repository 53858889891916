import styled from '@emotion/styled';
import React from 'react';

import { COLORS, TextButton, Text } from '@clutter/clean';
import { Currency } from '@shared/components/helpers';
import { TrackedClick } from '@portal/components/wt/tracked_click';
import { pickupServicePackageUpdateURL } from '@portal/config/routes';
import { useHistory } from 'react-router';
import { DoorToDoor__BundleEnum, PackageKindEnum } from '@portal/schema';
import { AppointmentDetailsOrder } from '../full_panel';
import { LineItem } from '../../line_item';

const PAGE_NAME = 'portal:appointment_details';

const ActionButton = styled(TextButton)`
  color: ${COLORS.tealPrimary};
  font-weight: 500;
  &:hover {
    color: ${COLORS.tealBrand};
    text-decoration: none;
  }

  cursor: pointer;
`;

const ActionButtonText = styled(Text.Button)`
  font-size: 16px;
`;

function editServicePackageTrackingParams(orderID: string) {
  return {
    pageName: PAGE_NAME,
    container: 'appointment_details',
    action: 'click',
    objectType: 'button',
    objectName: 'edit_service_package',
    label: 'Edit',
    value: pickupServicePackageUpdateURL(orderID),
    order_id: orderID,
  };
}

export const PickupServicePackageLineItem: React.FC<{
  order: AppointmentDetailsOrder;
}> = ({ order }) => {
  const history = useHistory();

  const servicePackageOptions = order.pickupServicePackageOptions;

  const currentServicePackageKind = order.tags.find((tag) => tag === 'Packing Help')
    ? DoorToDoor__BundleEnum.PickupAndPacking
    : DoorToDoor__BundleEnum.Basic;

  const currentServicePackage = servicePackageOptions.find((option) => option.kind === currentServicePackageKind);
  const currentMaterialsCharge = order.accountPackages.find(({ kind }) => kind === PackageKindEnum.PackingSupplies);

  if (!currentServicePackage) {
    return null;
  }

  return (
    <>
      <LineItem
        label="Service Package"
        action={
          <TrackedClick params={editServicePackageTrackingParams(order.id)}>
            <ActionButton
              size="medium"
              onClick={() => {
                history.push(pickupServicePackageUpdateURL(order.id));
              }}
            >
              <ActionButtonText>{'Edit'}</ActionButtonText>
            </ActionButton>
          </TrackedClick>
        }
      >
        <div>{currentServicePackage.name}</div>
        {currentServicePackage.kind === DoorToDoor__BundleEnum.PickupAndPacking ? (
          <div>Unlimited Packing Materials</div>
        ) : (
          <div>Packing Help & Materials Not Included</div>
        )}
        {currentMaterialsCharge && currentMaterialsCharge.amount > 0 && (
          <Currency value={currentMaterialsCharge.amount} precision={'automatic'} />
        )}
      </LineItem>
    </>
  );
};
