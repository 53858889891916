import { movingServicePackageUpdateURL, orderMovingAddressURL, orderURL } from '@portal/config/routes';
import { Box, Text, mq, Accordion, COLORS } from '@clutter/clean';
import styled from '@emotion/styled';
import * as React from 'react';
import { useHistory } from 'react-router';
import { DateTime, Duration } from 'luxon';
import { Currency, Spacer } from '@shared/components/helpers';
import { Phone } from '@shared/components/helpers/phone';
import { OrderSubtypeEnum, useMoveReviewQuery } from '@portal/schema';
import { Spinner } from '@admin/components/spinner';
import { dateDetailsMap } from '@portal/utils/scheduling';
import { MultiDayTimeline } from '@portal/components/orders/home/appointment_details/info_list/multi_day_timeline';
import { BaseStepType } from '../../data';
import { StepContainer } from '../../step_container';
import { StepName } from '../data';
import { editButtonTrackingParams, SummaryItem } from './summary_item';
import { WalkthroughCsat } from './walkthrough_csat';

const SummaryContainer = styled.div`
  display: grid;
  grid-gap: 16px;
  margin-top: 20px;

  ${mq({
    gridTemplateColumns: ['repeat(1, 1fr)', null, 'repeat(4, 1fr)'],
  })};
`;

const FAQAnswer = styled(Text.Body)`
  padding: 0px 24px 24px 24px;
`;

const MoveReview: React.FC<BaseStepType> = (props) => {
  const {
    values: { selectedItemCategories, priceMatchGuaranteeEligible },
    orderID,
    goToStep,
    createStepTransition,
  } = props;

  const history = useHistory();

  const { data, loading } = useMoveReviewQuery({
    variables: { orderID },
  });

  const order = data?.order;

  if (loading || !order) {
    return <Spinner />;
  }

  const {
    account: { customer },
    address,
    laborRate,
    movers,
    movingOperation,
    scheduled,
    window,
    contact,
    movingPricingSummary,
    permissions: { whiteGloveTestEligible },
  } = order;

  const numItems = selectedItemCategories?.reduce((count, obj) => count + obj.quantity, 0);

  const onNext = () => {
    createStepTransition({
      name: 'virtual_walkthrough',
      actionName: 'flow_completed',
      position: 0,
      values: {},
    });
    history.push(orderURL(orderID));
  };

  const contactInfo = contact?.name ? (
    <>
      {contact.name}
      <br />
      {<Phone value={contact.phone} />}
    </>
  ) : (
    <>
      {customer.name}
      <br />
      {customer.email}
      <br />
      {<Phone value={customer.phone} />}
    </>
  );

  const FROM_ISO_OPTIONS = { setZone: true };

  const StandardConfirmation: React.FC<{ scheduledDate: DateTime; arrivalWindow: Duration }> = ({
    scheduledDate,
    arrivalWindow,
  }) => (
    <p>
      {scheduledDate.weekdayShort + ', ' + scheduledDate.toLocaleString(DateTime.DATE_MED)}
      <br />
      {scheduledDate.toLocaleString(DateTime.TIME_SIMPLE)} -{' '}
      {scheduledDate.plus(arrivalWindow).toLocaleString(DateTime.TIME_SIMPLE)}
    </p>
  );

  const scheduledDate = DateTime.fromISO(scheduled, FROM_ISO_OPTIONS);
  const arrivalWindow = Duration.fromISO(window);

  const originAddressStreet = address?.street;
  const originAddressCityStateZip = address && `${address.city}, ${address.stateAbbreviation} ${address.zip}`;

  const destinationAddress = movingOperation?.destinationAddress;
  const destinationAddressStreet = destinationAddress?.street;
  const destinationAddressCityStateZip =
    destinationAddress &&
    `${destinationAddress.city}, ${destinationAddress.stateAbbreviation} ${destinationAddress.zip}`;

  const materialPackageSetEntry = movingPricingSummary?.materialPackageSetEntry;
  const flatRate = movingPricingSummary?.flatRateAmount;
  const boxKitName = materialPackageSetEntry?.package.name.replace('Packing Supplies', 'Box Kit');
  const boxKitPrice = materialPackageSetEntry && `$${materialPackageSetEntry.fixedCost}`;

  const editContactURL = orderURL(orderID, 'contact');
  const editScheduledURL = orderURL(orderID, 'reschedule');
  const editOriginURL = `${orderMovingAddressURL(orderID)}?kind=origin`;
  const editDestinationURL = `${orderMovingAddressURL(orderID)}?kind=destination`;
  const editMaterialsURL = orderURL(orderID, 'packing_bundles');

  const multiDayOrder = order.multiDay;
  const nonTravelSourceAndSuccessors = order.nonTravelSourceAndSuccessors.filter(
    (o) => o.subtype !== OrderSubtypeEnum.Travel,
  );
  const timelineDetailsMap = dateDetailsMap(nonTravelSourceAndSuccessors);

  return (
    <StepContainer {...props} canNext={true} next={onNext} nextLabel={'Close Walkthrough'}>
      <Box maxWidth="768px" margin="0 auto">
        <Text.SmallCaps>Virtual Walkthrough</Text.SmallCaps>
        <Text.Title size="medium">Review your information</Text.Title>
        <Spacer height="1rem" />
        <Text.Title size="extraSmall">Does everything look correct?</Text.Title>
        <SummaryContainer>
          <SummaryItem
            name="Contact"
            value={contactInfo}
            onEdit={() => history.push(editContactURL)}
            trackingParams={editButtonTrackingParams(orderID, editContactURL, 'edit_contact_button')}
          />
          {multiDayOrder ? (
            <SummaryItem
              name="Timeline"
              value={
                <>
                  <br />
                  <MultiDayTimeline timelineDetailsMap={timelineDetailsMap} showArrivalTimes={true} sizing={'small'} />
                </>
              }
            />
          ) : (
            <SummaryItem
              name="Date & time"
              value={<StandardConfirmation scheduledDate={scheduledDate} arrivalWindow={arrivalWindow} />}
              onEdit={() => history.push(editScheduledURL)}
              trackingParams={editButtonTrackingParams(orderID, editScheduledURL, 'edit_scheduled_button')}
            />
          )}
          <SummaryItem
            name="Starting address"
            value={
              <>
                {originAddressStreet}
                <br />
                {originAddressCityStateZip}
              </>
            }
            onEdit={() => history.push(editOriginURL)}
            trackingParams={editButtonTrackingParams(orderID, editOriginURL, 'edit_origin_button')}
          />
          <SummaryItem
            name="Destination address"
            value={
              <>
                {destinationAddressStreet}
                <br />
                {destinationAddressCityStateZip}
              </>
            }
            onEdit={() => history.push(editDestinationURL)}
            trackingParams={editButtonTrackingParams(orderID, editDestinationURL, 'edit_destination_button')}
          />
          <SummaryItem
            name="Items"
            value={`${numItems} items`}
            onEdit={() => goToStep(StepName.ItemInventory)}
            trackingParams={editButtonTrackingParams(orderID, 'edit_items_step', 'edit_items_button')}
          />
          <SummaryItem name="Movers" value={`${movers}`} />
          {flatRate ? (
            <SummaryItem name="Flat rate" value={<Currency value={flatRate} precision="automatic" />} />
          ) : (
            <>
              <SummaryItem name="Hourly rate" value={`$${laborRate.amount}/hr`} />
              <SummaryItem
                name={`${boxKitName || 'Box Kit'}`}
                value={`${boxKitPrice || 'Declined'}`}
                onEdit={
                  whiteGloveTestEligible
                    ? () => history.push(movingServicePackageUpdateURL(orderID))
                    : () => history.push(editMaterialsURL)
                }
                trackingParams={editButtonTrackingParams(orderID, editMaterialsURL, 'edit_materials_button')}
              />
            </>
          )}
        </SummaryContainer>
        <Spacer height="2rem" />
        <WalkthroughCsat orderID={orderID} />
        <Spacer height="2rem" />
        <Text.Title size="extraSmall">Frequently Asked Questions</Text.Title>
        <Accordion.Group>
          <Accordion.Item name="item_1" label={'Should I expect to hear back from Clutter?'}>
            <FAQAnswer>
              No, most walkthroughs don’t require any further follow-up so you should not expect to hear back from us.
              If we do have questions, we will contact you by phone, text, and email.
            </FAQAnswer>
          </Accordion.Item>
          <Accordion.Item name="item_2" label={'Can I make updates to my walkthrough?'}>
            <FAQAnswer>
              Yes, if there are updates to the items you are moving
              {!whiteGloveTestEligible && ' or your packing needs have changed'}, you can make changes at any time up to
              3 days before your appointment. We will only move the items that are listed in your walkthrough, so please
              be specific.
            </FAQAnswer>
          </Accordion.Item>
          {priceMatchGuaranteeEligible && (
            <Accordion.Item name="item_3" label={'How do I submit a price match request?'}>
              <FAQAnswer>
                We match prices from professional moving competitors. The move details and item inventory must be
                identical in order to qualify and the quote must not be older than 14 days. If the details of your move
                change after your Price Match was approved, then you must submit a new request. Please forward the quote
                you would like matched to{' '}
                <a href={`mailto:pricematch@clutter.com`}>
                  <Text color={COLORS.tealPrimary}>pricematch@clutter.com</Text>
                </a>{' '}
                and allow 48 hours for processing.
              </FAQAnswer>
            </Accordion.Item>
          )}
          <Accordion.Item name="item_4" label={'What happens next?'}>
            <FAQAnswer>
              We’ll use the information you provided to build the best team for your appointment. Please ensure a valid
              payment method is on file for the deposit and authorization check which are charged 48 hours before the
              appointment. In the meantime, expect emails and texts from us leading up to your appointment to help you
              prepare.
            </FAQAnswer>
          </Accordion.Item>
        </Accordion.Group>
      </Box>
    </StepContainer>
  );
};

export { MoveReview };
