import * as React from 'react';

import styled from '@emotion/styled';

import { Box, BREAKPOINTS, Button, COLORS, FontWeight, Modal, mq, SANS_SERIF_FONT_FAMILY, Text } from '@clutter/clean';

import checkCircle from '@portal/images/icons/check-circle.svg';
import xCircle from '@portal/images/icons/x_circle.svg';
import { Moving__WhiteGloveBundleEnum } from '@portal/schema';
import { InfoTooltip } from '../../home/appointment_details/info_list/info_tooltip';

type MatrixContent = {
  service: {
    name: string;
    tooltip?: string;
  };
  basic: boolean;
  packingHelp: boolean;
  fullService: boolean;
};

const matrixContent: MatrixContent[] = [
  {
    service: {
      name: 'Truck, transportation, fuel, and tolls',
      tooltip: 'There are no hidden fees for stairs, elevators, or shuttle services.',
    },
    basic: true,
    packingHelp: true,
    fullService: true,
  },
  {
    service: {
      name: 'Floor and corner protection',
      tooltip: 'We’ll protect your home or apartment building from bumps and scrapes.',
    },
    basic: true,
    packingHelp: true,
    fullService: true,
  },
  {
    service: {
      name: 'Protective furniture wrapping & blankets',
      tooltip: 'We always protect large and fragile items with moving blankets and edge protectors.',
    },
    basic: true,
    packingHelp: true,
    fullService: true,
  },
  {
    service: {
      name: 'Online virtual walkthrough',
      tooltip: 'Use our self-serve tool to let us know what’s moving and help us prepare.',
    },
    basic: true,
    packingHelp: true,
    fullService: true,
  },
  {
    service: {
      name: 'Certificate of Insurance',
      tooltip: 'After you book your move, you can request an insurance certificate online.',
    },
    basic: true,
    packingHelp: true,
    fullService: true,
  },
  {
    service: {
      name: 'TV dismounting',
      tooltip: 'We will uninstall your wall-mounted TV and bracket.',
    },
    basic: false,
    packingHelp: true,
    fullService: true,
  },
  {
    service: {
      name: 'Disassembly of basic furniture',
      tooltip: 'We will disassemble basic furniture to maximize space and keep your items safe.',
    },
    basic: false,
    packingHelp: true,
    fullService: true,
  },
  {
    service: {
      name: 'Resassembly of basic furniture',
      tooltip: 'Whatever we disassemble, we will reassemble at your destination.',
    },
    basic: false,
    packingHelp: true,
    fullService: true,
  },
  {
    service: {
      name: 'Packing help',
      tooltip: 'We’ll pack and protect any items stored in Clutter-provided packaging.',
    },
    basic: false,
    packingHelp: true,
    fullService: true,
  },
  {
    service: {
      name: 'Boxes and packing materials',
      tooltip:
        'Includes small, medium, and large boxes, wardrobe boxes, dish packs, mirror packs, TV boxes, and lamp boxes.',
    },
    basic: false,
    packingHelp: true,
    fullService: true,
  },
  {
    service: {
      name: 'Unpacking help',
      tooltip: 'We’ll unpack boxes and place them on a flat surface of your choosing.',
    },
    basic: false,
    packingHelp: false,
    fullService: true,
  },
  {
    service: {
      name: 'Our most experienced movers',
      tooltip: 'We’ll send Move & Pack Specialists who have been with Clutter for years.',
    },
    basic: false,
    packingHelp: false,
    fullService: true,
  },
  {
    service: {
      name: 'Concierge Service',
      tooltip: 'Our concierge team will proactively contact you to ensure your move goes smoothly.',
    },
    basic: false,
    packingHelp: false,
    fullService: true,
  },
  {
    service: {
      name: 'Additional stop',
      tooltip: 'Stop at a storage unit or second location along your moving route for no additional fee.',
    },
    basic: false,
    packingHelp: false,
    fullService: true,
  },
];

const Table = styled.table<{ selectedColumn: number }>`
  border-collapse: collapse;
  position: relative;
  table-layout: fixed;
  width: 100%;

  &::before {
    content: '';
    height: calc(100% + 1px);
    border: 2px solid ${COLORS.tealPrimary};
    top: 0;
    position: absolute;
    left: calc((${({ selectedColumn }) => selectedColumn + 1} * 20%) - 1px);
    box-sizing: border-box;
    border-radius: 4px;

    ${mq({
      width: ['calc(16% + 2px)', null, 'calc(20% + 2px)'],
    })}

    @media (max-width: ${BREAKPOINTS.MD}) {
      right: calc((${({ selectedColumn }) => 3 - selectedColumn} * 16%) - 1px);
      left: initial;
    }
  }

  td {
    border: 1px solid #ddd;
  }

  td:nth-child(4n + ${({ selectedColumn }) => selectedColumn + 1}) {
    background: ${COLORS.tealBackground};
  }

  td.packageName {
    text-align: center;
    padding: 8px 0;
  }

  td.description {
    text-align: left;

    ${mq({
      padding: ['12px', null, '12px 16px'],
    })}
  }

  td.check {
    text-align: center;
  }

  td.empty {
    border: none;
  }
`;

const Icon = styled.img`
  width: 20px;
`;

const ServiceDescription = styled(Text.Callout)`
  ${mq({
    fontSize: ['12px', null, 'initial'],
  })}
`;

const DescriptionHeader = styled.th`
  ${mq({
    width: ['52%', null, '40%'],
  })}
`;

const CheckHeader = styled.th`
  ${mq({
    width: ['16%', null, '20%'],
  })}
`;

const ButtonRow = styled.tr`
  ${mq({ display: ['none', null, 'table-row'] })}
`;

const StyledButton = styled(Button)`
  background: white;
`;

const ServiceDescriptionCell: React.FC<{
  service: { name: string; tooltip?: string };
}> = ({ service }) => (
  <td className="description">
    <Box.Flex alignItems="center" gap="8px">
      <Box.FlexItem flexGrow={1} flexShrink={1}>
        <ServiceDescription>{service.name}</ServiceDescription>
      </Box.FlexItem>
      {service?.tooltip && (
        <Box.FlexItem flexGrow={0} flexShrink={0}>
          <InfoTooltip text={service.tooltip} />
        </Box.FlexItem>
      )}
    </Box.Flex>
  </td>
);

const ServiceCheckCell: React.FC<{ checked: boolean }> = ({ checked }) => (
  <td className="check">
    <Icon src={checked ? checkCircle : xCircle} />
  </td>
);

const CheckedRow: React.FC<{ row: MatrixContent }> = ({ row }) => (
  <tr>
    <ServiceDescriptionCell service={row.service} />
    <ServiceCheckCell checked={row.basic} />
    <ServiceCheckCell checked={row.packingHelp} />
    <ServiceCheckCell checked={row.fullService} />
  </tr>
);

const PackageName = styled(Text)`
  font-weight: ${FontWeight.Medium};

  ${mq({
    fontFamily: [`${SANS_SERIF_FONT_FAMILY}`, null, 'Recoleta'],
    fontSize: ['12px', null, '20px'],
    writingMode: ['vertical-lr', null, 'initial'],
  })}
`;

const ButtonCell: React.FC<{
  value: Moving__WhiteGloveBundleEnum;
  onSelectBundle(bundle: Moving__WhiteGloveBundleEnum): void;
}> = ({ value, onSelectBundle }) => (
  <td>
    <Box.Flex width="100%" justifyContent="center" alignItems="center" padding="8px 0">
      <StyledButton
        onClick={() => {
          onSelectBundle(value);
        }}
        size="small"
        kind="secondary"
      >
        Select
      </StyledButton>
    </Box.Flex>
  </td>
);

export const ComparisonModal: React.FunctionComponent<{
  selectedBundle: Moving__WhiteGloveBundleEnum;
  isOpen: boolean;
  handleModalClose(): void;
  onSelectBundle(bundle: Moving__WhiteGloveBundleEnum): void;
}> = ({ selectedBundle, isOpen, handleModalClose, onSelectBundle }) => {
  const resolveSelectedColumn = () => {
    switch (selectedBundle) {
      case Moving__WhiteGloveBundleEnum.Basic:
        return 1;
      case Moving__WhiteGloveBundleEnum.PackingHelp:
        return 2;
      case Moving__WhiteGloveBundleEnum.FullService:
        return 3;
    }
  };

  const Container = styled.div`
    display: flex;
    flex-direction: column;
    overflow: scroll;
    padding: 64px 24px 40px;
    height: calc(100vh - 36px); /* Fallback */
    height: calc(100dvh - 36px);
    max-width: 768px;

    ${mq({
      width: ['calc(100vw - 36px)', '90vw'],
      height: [null, '90vh'],
    })}
  `;

  return (
    <Modal handleModalClose={handleModalClose} isOpen={isOpen} includeCloseButton>
      <Container>
        <Box margin={['0 0 16px', null, '0 0 24px 0']} textAlign="center">
          <Text.Title size="small" color={COLORS.tealDark}>
            Which package should I choose?
          </Text.Title>
        </Box>
        <Table selectedColumn={resolveSelectedColumn()}>
          <thead>
            <DescriptionHeader />
            <CheckHeader />
            <CheckHeader />
            <CheckHeader />
          </thead>
          <tbody>
            <tr>
              <td className="empty"></td>
              <td className="packageName">
                <PackageName>Basic</PackageName>
              </td>
              <td className="packageName">
                <PackageName>Packing Help</PackageName>
              </td>
              <td className="packageName">
                <PackageName>Full Service</PackageName>
              </td>
            </tr>
            {matrixContent.map((value, i) => (
              <CheckedRow key={i} row={value} />
            ))}
            <ButtonRow>
              <td className="empty"></td>
              <ButtonCell value={Moving__WhiteGloveBundleEnum.Basic} onSelectBundle={onSelectBundle} />
              <ButtonCell value={Moving__WhiteGloveBundleEnum.PackingHelp} onSelectBundle={onSelectBundle} />
              <ButtonCell value={Moving__WhiteGloveBundleEnum.FullService} onSelectBundle={onSelectBundle} />
            </ButtonRow>
          </tbody>
        </Table>
      </Container>
    </Modal>
  );
};
