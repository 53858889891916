import React from 'react';

import { COLORS, Text } from '@clutter/clean';

import bedframe from '@portal/images/illustrations/bedframe.svg';
import tracking from '@portal/images/illustrations/location_truck_tracking.svg';
import monitor from '@portal/images/illustrations/monitor.svg';
import moverPackingBoxes from '@portal/images/illustrations/mover_packing_boxes.svg';
import wavingMover from '@portal/images/illustrations/mover_waving_small.svg';
import moverWithCart from '@portal/images/illustrations/mover_with_cart_and_boxes.svg';
import moversWithTv from '@portal/images/illustrations/movers_with_tv_small.svg';
import truck from '@portal/images/illustrations/moving_truck_small.svg';
import phoneGpsTracking from '@portal/images/illustrations/phone_gps_tracking.svg';
import protectionPlan from '@portal/images/illustrations/protection_plan_basic_small.svg';
import tape from '@portal/images/illustrations/tape_multi_small.svg';

export type ValueProps = {
  title: React.ReactNode;
  values: Array<{ icon: string; description: string }>;
};

export const basicBundleValueProps = (isMobile: boolean): ValueProps => ({
  title: 'Includes',
  values: isMobile
    ? [
        {
          icon: truck,
          description: 'Truck, dollies, and blankets',
        },
        { icon: moversWithTv, description: 'Full-time professional movers' },
      ]
    : [
        {
          icon: truck,
          description: 'Truck, dollies, and blankets',
        },
        { icon: moversWithTv, description: 'Full-time professional movers' },
        {
          icon: phoneGpsTracking,
          description: 'Real-time GPS tracking',
        },
        {
          icon: protectionPlan,
          description: 'Certificate of Insurance',
        },
      ],
});

export const packingHelpBundleValueProps = (isMobile: boolean, materialsCost: number): ValueProps => ({
  title: (
    <>
      <Text color={COLORS.panther}>Basic</Text> plus...
    </>
  ),
  values: isMobile
    ? [
        {
          icon: monitor,
          description: 'We dismount TVs',
        },
        {
          icon: bedframe,
          description: 'Furniture disassembly & reassembly included',
        },
      ]
    : [
        {
          icon: moverPackingBoxes,
          description: 'We help pack, you unpack',
        },
        {
          icon: tape,
          description: `${
            materialsCost === 0
              ? `We bring boxes and packing supplies`
              : `We bring boxes and packing supplies for $${materialsCost}`
          }`,
        },
        {
          icon: monitor,
          description: 'We dismount TVs',
        },
        {
          icon: bedframe,
          description: 'Furniture disassembly & reassembly included',
        },
      ],
});

export const fullServiceBundleValueProps = (isMobile: boolean): ValueProps => ({
  title: (
    <>
      <Text color={COLORS.panther}>Packing Help</Text> plus...
    </>
  ),
  values: isMobile
    ? [
        {
          icon: tracking,
          description: 'Extra stop, if needed',
        },
        {
          icon: wavingMover,
          description: 'Concierge service',
        },
      ]
    : [
        {
          icon: moversWithTv,
          description: 'We pack AND unpack',
        },
        {
          icon: moverWithCart,
          description: 'Our most experienced movers',
        },
        {
          icon: wavingMover,
          description: 'Concierge service',
        },
        {
          icon: tracking,
          description: 'Extra stop, if needed',
        },
      ],
});
