import { fulfilledTermCommitment } from '@shared/utils/fulfilled_term_commitment';
import { DateTime } from 'luxon';
import { OrderServiceTypeEnum } from '@portal/schema';
import { ISkipProps } from './types';

export const fulfilledTermOrUnconfirmed = ({
  subtype,
  scheduledDate,
  termCommitment,
  confirmedDate,
  serviceType,
}: ISkipProps) => {
  if (!confirmedDate && serviceType !== OrderServiceTypeEnum.Shipment) {
    return true;
  }

  if (scheduledDate) {
    return fulfilledTermCommitment(scheduledDate, subtype, termCommitment);
  } else {
    return fulfilledTermCommitment(DateTime.local(), subtype, termCommitment);
  }
};
