import { RouteWithTitle } from '@portal/components/shared/route_with_title';
import * as React from 'react';
import { Redirect, Switch } from 'react-router-dom';

import {
  disposalConfirmationURL,
  orderMovingAddressURL,
  orderStepURL,
  ordersURL,
  orderURL,
  movingServicePackageUpdateURL,
  pickupServicePackageUpdateURL,
} from '@portal/config/routes';
import { Theme } from '@portal/components/helpers/theme';

import { Address as OrderAddress } from './orders/address';
import { Address as MovingAddress } from './orders/moving/address';
import { Steps as OrderCancellation } from './orders/cancel/steps';
import { Contact as OrderContact } from './orders/contact';
import { Index as OrderIndex } from './orders/index';
import { Details as OrderDetails } from './orders/details';
import { Form as OrderForm } from './orders/form';
import { Reschedule as OrderReschedule } from './orders/reschedule';
import { CertificateOfInsurance as OrderCertificateOfInsurance } from './orders/certificate_of_insurance';
import { SupplyKits as OrderSupplyKits } from './orders/supply_kits';
import { Signatures as OrderSignatures } from './orders/signatures';
import { TruckProtection } from './orders/truck_protection';
import { PackingBundles } from './orders/moving/packing_bundles';
import { Checkout as OrderFinancingCheckout } from './orders/financing/checkout';
import { Summary as OrderFinancingSummary } from './orders/financing/summary';
import { DisposalConfirmation } from './orders/disposal_confirmation';

import { PickupInventory as OrderPickupInventory } from './orders/pickup_inventory';
import { ReturnInventory as OrderReturnInventory } from './orders/return_inventory';
import { EstimationFlow } from './orders/estimation';
import { PrintLabels } from './orders/print_labels';
import { PayDeposit } from './pay/pay_deposit';
import { MovingServicePackageUpdate } from './orders/service_package/moving/update';
import { PickupServicePackageUpdate } from './orders/service_package/pickup/update';

export const Orders: React.FC = () => (
  <Theme name="default">
    <Switch>
      <RouteWithTitle exact path={ordersURL()} component={OrderIndex} title="Appointments" />
      <RouteWithTitle exact path={orderURL(':orderID')} component={OrderDetails} title="Appointment Details" />
      <RouteWithTitle exact path={orderStepURL(':step')} component={OrderForm} title="Schedule an Appointment" />
      <RouteWithTitle
        path={orderURL(':orderID', 'cancel')}
        component={OrderCancellation}
        title="Cancel an Appointment"
      />
      <RouteWithTitle
        exact
        path={orderURL(':orderID', 'thanks')}
        component={() => <Redirect push to={ordersURL()} />}
        title="Appointment Confirmed"
      />
      <RouteWithTitle
        exact
        path={orderURL(':orderID', 'address')}
        component={OrderAddress}
        title="Appointment Address"
      />
      <RouteWithTitle
        exact
        path={orderMovingAddressURL(':orderID')}
        component={MovingAddress}
        title="Appointment Addresses"
      />
      <RouteWithTitle
        exact
        path={orderURL(':orderID', 'contact')}
        component={OrderContact}
        title="Appointment Contact"
      />
      <RouteWithTitle
        exact
        path={orderURL(':orderID', 'reschedule')}
        component={OrderReschedule}
        title="Reschedule an Appointment"
      />
      <RouteWithTitle
        exact
        path={orderURL(':orderID', 'estimated_items')}
        component={OrderPickupInventory}
        title="Appointment Inventory"
      />
      <RouteWithTitle
        exact
        path={orderURL(':orderID', 'estimation_flow')}
        component={EstimationFlow}
        title="Estimation Flow"
      />
      <RouteWithTitle
        exact
        path={orderURL(':orderID', 'inventory')}
        component={OrderReturnInventory}
        title="Appointment Inventory"
      />
      <RouteWithTitle exact path={orderURL(':orderID', 'coi')} component={OrderCertificateOfInsurance} title="COI" />
      <RouteWithTitle
        exact
        path={orderURL(':orderID', 'supply_kits')}
        component={OrderSupplyKits}
        title="Supply Kits"
      />
      <RouteWithTitle exact path={orderURL(':orderID', 'signatures')} component={OrderSignatures} title="Signatures" />
      <RouteWithTitle
        exact
        path={orderURL(':orderID', 'truck_protection')}
        component={TruckProtection}
        title="In-Truck Protection"
      />
      <Redirect from={orderURL(':orderID', 'packing_supplies')} to={orderURL(':orderID', 'packing_bundles')} />
      <Redirect from={orderURL(':orderID', 'virtual_walkthrough')} to={orderURL(':orderID', 'estimation_flow')} />
      <RouteWithTitle
        exact
        path={orderURL(':orderID', 'packing_bundles')}
        component={PackingBundles}
        title="Packing Bundles"
      />
      <RouteWithTitle
        exact
        path={orderURL(':orderID', 'financing/checkout')}
        component={OrderFinancingCheckout}
        title="Checkout"
      />
      <RouteWithTitle
        exact
        path={orderURL(':orderID', 'financing/summary')}
        component={OrderFinancingSummary}
        title="Financing"
      />
      <RouteWithTitle exact path={orderURL(':orderID', 'print_labels')} component={PrintLabels} title="Print Labels" />
      <RouteWithTitle
        exact
        path={orderURL(':orderID', 'pay_deposit')}
        component={PayDeposit}
        title="Pay your Deposit"
      />
      <RouteWithTitle
        exact
        path={disposalConfirmationURL(':orderID')}
        component={DisposalConfirmation}
        title="Disposal Confirmation"
      />
      <RouteWithTitle
        exact
        path={movingServicePackageUpdateURL(':orderID')}
        component={MovingServicePackageUpdate}
        title="Update Service Package"
      />
      <RouteWithTitle
        exact
        path={pickupServicePackageUpdateURL(':orderID')}
        component={PickupServicePackageUpdate}
        title="Update Service Package"
      />
    </Switch>
  </Theme>
);
