import { SelectedItemCategories } from '@portal/components/orders/estimation/data';
import { Box, Button, Modal, Text, TextButton } from '@clutter/clean';
import React, { useState } from 'react';

export const ConfirmationModal: React.FC<{
  isOpen: boolean;
  onClose(): void;
  onReset(regenerate: boolean): void;
}> = ({ isOpen, onClose, onReset }) => (
  <Modal isOpen={isOpen} includeCloseButton={false} handleModalClose={onClose}>
    <Box padding="24px" width="calc(100vw - 48px)" maxWidth="582px">
      <Box textAlign="center" margin="0 0 16px">
        <Text.Title size="medium">Reset item selections?</Text.Title>
      </Box>
      <Text.Body>Are you sure you want to reset all item selections and start again?</Text.Body>
      <Box.Flex margin="24px 0 0" gap="16px" justifyContent="flex-end" flexDirection={['column', 'row']}>
        <Button kind="secondary" onClick={onClose}>
          Nevermind
        </Button>
        <Button kind="destructive" onClick={() => onReset(false)}>
          Clear All Items
        </Button>
        <Button kind="destructive" onClick={() => onReset(true)}>
          Regenerate from Photos
        </Button>
      </Box.Flex>
    </Box>
  </Modal>
);

export const ResetButton: React.FC<{
  createItems(items: SelectedItemCategories): void;
  items?: SelectedItemCategories;
  regenerateFromPhotos: () => void;
}> = ({ items, createItems, regenerateFromPhotos }) => {
  const [isModalOpen, setIsModalOpen] = useState<boolean>(false);

  const onReset = (regenerate: boolean) => {
    createItems([]);
    setIsModalOpen(false);

    if (regenerate) {
      regenerateFromPhotos();
    }
  };

  return (
    <>
      {items && items.length > 0 && (
        <TextButton kind="destructive" onClick={() => setIsModalOpen(true)} size="small">
          Reset Items
        </TextButton>
      )}
      <ConfirmationModal isOpen={isModalOpen} onClose={() => setIsModalOpen(false)} onReset={onReset} />
    </>
  );
};
