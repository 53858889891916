import styled from '@emotion/styled';
import React, { useState } from 'react';

import { Duration } from 'luxon';
import { COLORS, TextButton, Text } from '@clutter/clean';
import { Currency, Pluralize } from '@shared/components/helpers';
import { TrackedClick } from '@portal/components/wt/tracked_click';
import { getMovingPackingMaterials } from '@portal/utils/packing_materials';
import { movingServicePackageUpdateURL } from '@portal/config/routes';
import { useHistory } from 'react-router';
import { AppointmentDetailsOrder } from '../full_panel';
import { LineItem } from '../../line_item';
import { MoverCountModal } from '../mover_count_modal/mover_count_modal';

const PAGE_NAME = 'portal:appointment_details';

const ActionButton = styled(TextButton)`
  color: ${COLORS.tealPrimary};
  font-weight: 500;
  &:hover {
    color: ${COLORS.tealBrand};
    text-decoration: none;
  }

  cursor: pointer;
`;

const ActionButtonText = styled(Text.Button)`
  font-size: 16px;
`;

const FinePrint = styled.div`
  color: ${COLORS.storm};
  font-size: 12px;
  line-height: 14px;
`;

function customizeMoverCountButtonTrackingParams(orderID: string) {
  return {
    pageName: PAGE_NAME,
    container: 'appointment_details',
    action: 'click',
    objectType: 'button',
    objectName: 'customize_mover_count',
    label: 'Customize',
    value: 'modal',
    order_id: orderID,
  };
}

function editServicePackageTrackingParams(orderID: string) {
  return {
    pageName: PAGE_NAME,
    container: 'appointment_details',
    action: 'click',
    objectType: 'button',
    objectName: 'edit_service_package',
    label: 'Edit',
    value: movingServicePackageUpdateURL(orderID),
    order_id: orderID,
  };
}

export const MoveLaborLineItem: React.FC<{
  order: AppointmentDetailsOrder;
  flatRateAmount?: number;
  refetchData: () => void;
}> = ({ order, flatRateAmount, refetchData }) => {
  const [showMoverCountModal, setShowMoverCountModal] = useState<boolean>(false);

  const hourlyLaborCost = order.laborRate.amount;
  const minimumHours = order.requiredLaborDuration ? Duration.fromISO(order.requiredLaborDuration).as('hours') : 0;
  const pricingSummary = order.movingPricingSummary;
  const isPartnerMove = order.bookingPartner && pricingSummary;
  const fixedDuration =
    isPartnerMove && pricingSummary?.fixedDuration
      ? Duration.fromISO(pricingSummary.fixedDuration).toFormat('h')
      : undefined;
  const canEditMoverCount = order.permissions.customerChangeableMoverCount;
  const longDistanceTransportationRate = order.movingOperation?.longDistanceTransportationRate;
  const longDistanceMinimumCargoWeightInPounds = order.movingOperation?.longDistanceMinimumCargoWeightInPounds;
  const longDistanceMinimumTransportFee = order.movingOperation?.longDistanceMinimumTransportFee;
  const whiteGloveTags = order.tags.filter(
    (tag) => tag.includes('Basic') || tag.includes('Packing Help') || tag.includes('Full Service'),
  );
  const packingSupplies = getMovingPackingMaterials(order);
  const whiteGloveTestEligible = whiteGloveTags.length > 0;

  const history = useHistory();

  const resolveWhiteGloveBundleName = () => {
    const tag = whiteGloveTags[0];
    if (tag.includes('Basic')) {
      return 'Basic Package';
    } else if (tag.includes('Packing Help')) {
      return 'Packing Help Package';
    } else if (tag.includes('Full Service')) {
      return 'Full Service Package';
    }
  };

  return (
    <>
      <LineItem
        label={
          whiteGloveTestEligible
            ? resolveWhiteGloveBundleName()
            : longDistanceTransportationRate
            ? 'Pack & Unpack'
            : 'Moving & Packing'
        }
        action={
          whiteGloveTestEligible || canEditMoverCount ? (
            <TrackedClick
              params={
                whiteGloveTestEligible
                  ? editServicePackageTrackingParams(order.id)
                  : customizeMoverCountButtonTrackingParams(order.id)
              }
            >
              <ActionButton
                size="medium"
                onClick={() => {
                  if (whiteGloveTestEligible) {
                    history.push(movingServicePackageUpdateURL(order.id));
                  } else {
                    setShowMoverCountModal(true);
                  }
                }}
              >
                <ActionButtonText>{whiteGloveTestEligible ? 'Edit' : 'Customize'}</ActionButtonText>
              </ActionButton>
            </TrackedClick>
          ) : undefined
        }
        description={
          <>
            {!flatRateAmount &&
              (longDistanceTransportationRate ? (
                <>
                  {longDistanceMinimumTransportFee ? (
                    <FinePrint>
                      Your Pack & Unpack rate only applies to the time your team spends on-site packing and unpacking.
                      It does not apply to any drive time or break time.
                    </FinePrint>
                  ) : (
                    <FinePrint>
                      {longDistanceTransportationRate.description}
                      {longDistanceMinimumCargoWeightInPounds &&
                        ` Your move includes a minimum of ${longDistanceMinimumCargoWeightInPounds} pounds.`}
                    </FinePrint>
                  )}
                </>
              ) : (
                <>
                  {minimumHours > 0 && !flatRateAmount && (
                    <FinePrint>
                      Your move includes a minimum of <Pluralize count={minimumHours} singular="hour" plural="hours" />.
                      After you've met your minimum, your hourly rate will be prorated to the minute.
                    </FinePrint>
                  )}
                  {isPartnerMove && flatRateAmount && (
                    <FinePrint>
                      If your move lasts more than {fixedDuration} hours, the hourly rate for additional hours will be
                      prorated to the minute.
                    </FinePrint>
                  )}
                </>
              ))}
          </>
        }
      >
        <div>
          {!flatRateAmount ? (
            <>
              <div>{hourlyLaborCost > 0 ? `$${hourlyLaborCost}/hr` : 'Free'}</div>
            </>
          ) : (
            <>
              {isPartnerMove ? (
                <>
                  <div>
                    <Currency value={pricingSummary.totalFixedAmount || 0} precision="automatic" /> for up to{' '}
                    {fixedDuration} hours
                  </div>
                  {pricingSummary.laborRate.amount > 0 && (
                    <div>
                      <Currency value={pricingSummary.laborRate.amount} precision="automatic" />
                      /hour for each additional hour
                    </div>
                  )}
                </>
              ) : (
                <Currency value={flatRateAmount || 0} precision="automatic" />
              )}
            </>
          )}
          <div>
            <Pluralize count={order.movers} singular="Mover" plural="Movers" />
          </div>
          {whiteGloveTestEligible && packingSupplies && (
            <div>
              {packingSupplies.name}(
              {packingSupplies.amount > 0 ? <Currency value={packingSupplies.amount} precision="automatic" /> : 'Free'})
            </div>
          )}
          {whiteGloveTestEligible && !packingSupplies && <div>Packing Supplies Not Included</div>}
        </div>
      </LineItem>
      {showMoverCountModal && (
        <MoverCountModal
          pageName={PAGE_NAME}
          hideModal={() => setShowMoverCountModal(false)}
          order={order}
          onUpdateMoverCount={refetchData}
        />
      )}
    </>
  );
};
