import React from 'react';

import { orderURL } from '@portal/config/routes';
import { Box } from '@clutter/clean';
import { getMovingPackingMaterials } from '@portal/utils/packing_materials';
import { AppointmentDetailsOrder } from '../full_panel';
import { LineItem, TrackedLinkAction } from '../../line_item';
import { wtLinkParams } from './constants';

function movingPackingEditParams(orderID: string, action: string) {
  return {
    ...wtLinkParams,
    objectName: 'moving_packing',
    label: 'Edit',
    order_id: orderID,
    action,
  };
}

export const MovePackingSuppliesLineItem: React.FC<{
  order: AppointmentDetailsOrder;
}> = ({ order }) => {
  const packingSupplies = getMovingPackingMaterials(order);
  const packingSuppliesCost = packingSupplies ? packingSupplies.amount || 0 : undefined;
  const packingSuppliesName = packingSupplies?.name || 'None';
  const whiteGloveTestEligible = order.permissions.whiteGloveTestEligible;

  if (whiteGloveTestEligible) {
    return null;
  }

  return (
    <LineItem
      label={<Box.Flex flexWrap="wrap">Packing Supplies</Box.Flex>}
      action={
        whiteGloveTestEligible || order.bookingPartner ? undefined : (
          <TrackedLinkAction
            params={{
              text: 'Edit',
              url: orderURL(order.id, 'packing_bundles'),
              trackingParams: movingPackingEditParams(order.id, 'Edit'),
            }}
          />
        )
      }
    >
      <div>{packingSuppliesName}</div>
      <div>
        {order.bookingPartner
          ? packingSuppliesCost
            ? 'Included'
            : 'Not Included'
          : packingSuppliesCost
          ? `$${packingSuppliesCost}`
          : ''}
      </div>
    </LineItem>
  );
};
