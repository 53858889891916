import * as React from 'react';
import { currency as format } from '@shared/utils/currency';

export const Currency: React.FC<{
  value: number | string;
  currency?: 'CAD' | 'USD' | 'cad' | 'usd';
  precision?: number | 'automatic';
  suffix?: React.ReactNode;
}> = ({ currency, value, precision, suffix }) => {
  value = Number(value);
  const decimals = precision === 'automatic' ? (Math.trunc(value) === value ? 0 : 2) : precision;
  const options =
    decimals !== undefined
      ? {
          minimumFractionDigits: decimals,
          maximumFractionDigits: decimals,
        }
      : undefined;

  return (
    <>
      {format(value, options)}
      {currency && <> {currency?.toUpperCase()}</>}
      {suffix}
    </>
  );
};
