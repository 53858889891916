import React from 'react';
import { ProgressBar } from '@clutter/clean';
import { Values } from './data';
import { Item, ItemContainer, ProgressContainer } from '../progress_helpers';

export const Progress: React.FC<{
  currentStep: number;
  goToStep: (stepName: string) => void;
  values: Values;
}> = ({ currentStep }) => {
  // Hide progress bar on first step or last step
  if (currentStep >= 4) return null;

  let percentage;
  if (currentStep < 1) {
    percentage = 0.25;
  } else if (currentStep < 2) {
    percentage = 0.5;
  } else if (currentStep < 3) {
    percentage = 0.75;
  } else {
    percentage = 1;
  }

  return (
    <ProgressContainer>
      <ProgressBar percentage={percentage} />
      <ItemContainer>
        <Item highlighted={percentage === 0.25} clickable={false}>
          Photos
        </Item>
        <Item highlighted={percentage === 0.5} clickable={false}>
          Items
        </Item>
        <Item highlighted={percentage === 0.75} clickable={false}>
          Notes
        </Item>
        <Item highlighted={percentage === 0.9} clickable={false}>
          Review
        </Item>
      </ItemContainer>
    </ProgressContainer>
  );
};
