import React, { useState } from 'react';

import { Box, Button, COLORS, Text } from '@clutter/clean';

import {
  AppointmentHubDocument,
  Moving__WhiteGloveBundleEnum,
  Status,
  useMovingServicePackageUpdateMutation,
  useMovingServicePackageUpdateOrderQuery,
} from '@portal/schema';
import { useHistory, useParams } from 'react-router';
import { ordersURL } from '@portal/config/routes';
import { useBreakpointFlags } from '@portal/utils';
import { Card } from './card';
import { ComparisonModal } from './comparison_modal';
import { basicBundleValueProps, fullServiceBundleValueProps, packingHelpBundleValueProps } from './value_props';

type ServicePackageOption = { whiteGloveBundle: Moving__WhiteGloveBundleEnum; moverCount: number };

export const MovingServicePackageUpdate: React.FC = () => {
  const { orderID } = useParams<{ orderID: string }>();

  const [highlightedBundle, setHighlightedBundle] = useState<Moving__WhiteGloveBundleEnum | undefined>(undefined);
  const [selectedServicePackage, setSelectedServicePackage] = useState<ServicePackageOption | undefined>(undefined);
  const [showComparisonModal, setShowComparisonModal] = useState(false);
  const [error, setError] = useState<string | undefined>(undefined);

  const history = useHistory();
  const { isMobile } = useBreakpointFlags();

  const [update, { loading: saving }] = useMovingServicePackageUpdateMutation({
    refetchQueries: [{ query: AppointmentHubDocument, variables: { orderID } }],
  });

  const { data: appointmentData } = useMovingServicePackageUpdateOrderQuery({
    variables: { orderID },
  });

  const order = appointmentData?.order;

  if (!order) {
    return null;
  }

  const currentBundle = order.tags.find((tag) => tag === 'Full Service')
    ? Moving__WhiteGloveBundleEnum.FullService
    : order.tags.find((tag) => tag === 'Packing Help')
    ? Moving__WhiteGloveBundleEnum.PackingHelp
    : Moving__WhiteGloveBundleEnum.Basic;

  const onCompare = (bundle: Moving__WhiteGloveBundleEnum) => {
    setHighlightedBundle(bundle);
    setShowComparisonModal(true);
  };

  const onMatrixSelectBundle = (bundle: Moving__WhiteGloveBundleEnum) => {
    const moverCount = options!.find((option) => option.whiteGloveBundle === bundle)!.moverCount;
    setSelectedServicePackage({ whiteGloveBundle: bundle, moverCount: moverCount });
    setShowComparisonModal(false);
  };

  const movingQuote = order.movingOperation?.movingQuote;

  const options = movingQuote?.whiteGloveMoverCountOptions;
  const minimumHours = movingQuote?.movingConfiguration
    ? movingQuote?.movingConfiguration?.requiredLaborDuration / 3600
    : 0;

  if (!options) {
    return null;
  }

  const basicBundleOption = options.find((option) => option.whiteGloveBundle === Moving__WhiteGloveBundleEnum.Basic);
  const packingHelpBundleOption = options.find(
    (option) => option.whiteGloveBundle === Moving__WhiteGloveBundleEnum.PackingHelp,
  );
  const fullServiceBundleOption = options.find(
    (option) => option.whiteGloveBundle === Moving__WhiteGloveBundleEnum.FullService,
  );

  if (!(basicBundleOption && packingHelpBundleOption && fullServiceBundleOption)) {
    return null;
  }

  const basicBundleLaborRate = basicBundleOption.laborRate?.amount || 0;
  const packingHelpBundleLaborRate = packingHelpBundleOption.laborRate?.amount || 0;
  const fullServiceBundleLaborRate = fullServiceBundleOption.laborRate?.amount || 0;

  const unlimitedPackingPackage = order.movingMaterialPackageSetEntries.find(
    (entry) => entry.package.name === 'Unlimited Packing Supplies',
  );

  const saveSelection = async () => {
    if (saving) {
      return;
    }

    if (!selectedServicePackage || selectedServicePackage.whiteGloveBundle === currentBundle) {
      history.push(ordersURL());
      return;
    }

    const response = await update({
      variables: {
        input: {
          orderID: order.id,
          bundle: selectedServicePackage.whiteGloveBundle!,
          moverCount: selectedServicePackage.moverCount,
        },
      },
    });

    const data = response.data?.movingServicePackageUpdate;
    if (data?.status === Status.Ok) {
      history.push(ordersURL());
    } else {
      setError(data?.error ?? 'Sorry, we are unable to update your service package at this time!');
    }
  };

  return (
    <Box margin={['48px 0 0', null, '72px 0 0']} textAlign="center">
      <Box margin="32px">
        <Text.Title size="medium" color={COLORS.tealDark}>
          Select Your Package
        </Text.Title>
      </Box>
      {highlightedBundle && (
        <ComparisonModal
          selectedBundle={highlightedBundle}
          isOpen={showComparisonModal}
          handleModalClose={() => setShowComparisonModal(false)}
          onSelectBundle={onMatrixSelectBundle}
        />
      )}
      <Box.Flex flexDirection={['column', null, null, 'row']} gap="12px" justifyContent="center">
        <Card
          testId="basicBundle"
          bannerValue="Lowest cost"
          title="Basic"
          subtitle="We move, you pack & unpack"
          selected={(selectedServicePackage?.whiteGloveBundle ?? currentBundle) === Moving__WhiteGloveBundleEnum.Basic}
          showPrice={true}
          flatRate={undefined}
          laborRate={basicBundleLaborRate}
          minimumHours={minimumHours}
          showBadge={false}
          valueProps={basicBundleValueProps(isMobile)}
          onCompare={() => onCompare(Moving__WhiteGloveBundleEnum.Basic)}
          onSelect={() =>
            setSelectedServicePackage({
              whiteGloveBundle: Moving__WhiteGloveBundleEnum.Basic,
              moverCount: basicBundleOption.moverCount,
            })
          }
        />
        <Card
          testId="packingHelpBundle"
          bannerValue="Best value"
          title="Packing Help"
          subtitle="We move & pack, you unpack"
          selected={
            (selectedServicePackage?.whiteGloveBundle ?? currentBundle) === Moving__WhiteGloveBundleEnum.PackingHelp
          }
          showPrice={true}
          flatRate={undefined}
          laborRate={packingHelpBundleLaborRate}
          minimumHours={minimumHours}
          showBadge={true}
          valueProps={packingHelpBundleValueProps(isMobile, unlimitedPackingPackage?.fixedCost || 0)}
          onCompare={() => onCompare(Moving__WhiteGloveBundleEnum.PackingHelp)}
          onSelect={() =>
            setSelectedServicePackage({
              whiteGloveBundle: Moving__WhiteGloveBundleEnum.PackingHelp,
              moverCount: packingHelpBundleOption.moverCount,
            })
          }
        />
        <Card
          testId="fullServiceBundle"
          bannerValue="Most Luxurious"
          title="Full Service"
          subtitle="We do everything!"
          selected={
            (selectedServicePackage?.whiteGloveBundle ?? currentBundle) === Moving__WhiteGloveBundleEnum.FullService
          }
          showPrice={true}
          flatRate={undefined}
          laborRate={fullServiceBundleLaborRate}
          minimumHours={minimumHours}
          showBadge={false}
          valueProps={fullServiceBundleValueProps(isMobile)}
          onCompare={() => onCompare(Moving__WhiteGloveBundleEnum.FullService)}
          onSelect={() =>
            setSelectedServicePackage({
              whiteGloveBundle: Moving__WhiteGloveBundleEnum.FullService,
              moverCount: fullServiceBundleOption.moverCount,
            })
          }
        />
      </Box.Flex>
      <Box margin="32px">
        <Button type="submit" className="primary" loading={saving} onClick={() => saveSelection()}>
          Save Selection
        </Button>
      </Box>
      {error && (
        <Box color={COLORS.toucan} margin="16px">
          <p>{error}</p>
        </Box>
      )}
    </Box>
  );
};
