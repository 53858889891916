import React from 'react';
import { RouteComponentProps } from 'react-router-dom';

import { Spinner } from '@portal/components/helpers';
import { useAppointmentHubQuery } from '@portal/schema';

import { VirtualWalkthroughFlow } from './virtual_walkthrough/flow';

export const EstimationFlow: React.FC<RouteComponentProps<{ orderID: string }>> = (props) => {
  const {
    match: {
      params: { orderID },
    },
  } = props;
  const { data, loading } = useAppointmentHubQuery({ variables: { orderID } });
  const order = data?.order;

  if (loading || !order) {
    return <Spinner />;
  }

  return <VirtualWalkthroughFlow {...props} />;
};
