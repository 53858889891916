import { DateTime } from 'luxon';

import { Account__TermCommitment as Admin__Account__TermCommitment } from '@admin/schema';
import { Account__TermCommitment as Portal__Account__TermCommitment, Maybe, OrderSubtypeEnum } from '@portal/schema';

type Shared__Account__TermCommitment = Admin__Account__TermCommitment | Portal__Account__TermCommitment;

export const fulfilledTermCommitment = (
  scheduledDate: DateTime,
  subtype?: OrderSubtypeEnum,
  termCommitment?: Maybe<Shared__Account__TermCommitment>,
) =>
  subtype !== OrderSubtypeEnum.Final ||
  !termCommitment?.contractEndDate ||
  scheduledDate >= DateTime.fromISO(termCommitment.contractEndDate!) ||
  termCommitment.fulfilled;
