import React from 'react';

import { Theme } from '@portal/components/helpers/theme';

import { UpdatePlan } from '../../../plan/update';

export enum estimationFitEnum {
  GoodFit = 'Good Fit',
  OverCapacity = 'Over Capacity',
  UnderCapacity = 'Under Capacity',
}

export const Plan: React.FC = () => (
  <Theme name="default">
    <UpdatePlan />
  </Theme>
);
